.hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    color: white;
    height: 100vh;
    background-color: #0b1f3b;
  
    /* Background image settings */
    background-image: url('./hero-bg.png'); /* Updated path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .hero-content {
    max-width: 90%;
    text-align: left;
    margin-left: 40%; /* Pushes content to the right */
    z-index: 2;
  }

  /* Variables */
:root {
    --color: #52C5C8;
}

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    height: 100%;
    z-index: 1; /* Place below the hero content */
}

.sparkle {
    position: absolute;
    background-color: var(--color);
    height: 7px;
    width: 7px;
    border-radius: 50%;
    filter: blur(1px);
    animation: move 5s ease-in-out;
}

@keyframes move {
    0% {
        opacity: 0;
        transform: translate(0, 100%);
    }

    50% {
        opacity: 1;
        transform: translate(500%, -100%);
    }

    100% {
        opacity: 0;
        transform: translate(1000%, 100%);
    }
}
  
  .hero-content h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 20px 0;
  }
  
  .hero-content .highlight {
    color: #4a90e2;
  }
  
  .hero-content p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .hero-buttons {
    display: flex;
    gap: 20px;
    margin: 20px 0;
  }
  
  .quote-button,
  .start-button {
    background-color: #4a90e2;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .quote-button:hover,
  .start-button:hover {
    background-color: #357abd;
  }
  
  @media (min-width: 768px) {
    /* Desktop view */
    .hero-section {
      justify-content: center; /* Aligns content to the right */
    }
    .hero-content {
      max-width: 40%;
    }
  }
  
  @media (max-width: 767px) {
    /* Mobile view */
    .hero-section {
      justify-content: center;
      text-align: center;
      background-position: center;
      height: 85vh;
      padding-top: 0;
    }
    .hero-content {
      max-width: 90%;
      margin: 0; /* Center content */
    }
    .hero-content h1 {
      font-size: 1.8rem;
    }
    .hero-content p {
      font-size: 0.9rem;
    }
    .hero-buttons {
      flex-direction: row;
    }
    .quote-button,
    .start-button {
      width: 100%;
      padding: 3% 3%;
      font-size: 14px;
    }
  }
  