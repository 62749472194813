.navbar_carte {
    width: 100%;
    display: flex;
    justify-content: space-around; 
    background: #F6F6F6;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 94%;
    margin: auto;
    margin-top:92px;
}

.navbar.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
    z-index: 1000; /* Ensure it is above other content */
}


.link{
    text-decoration: none;
    color: black;
    margin-left: 0%;
}

h6{
    margin: 0;
    font-size: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.save,.sms,.email_nav,.menu{
    cursor: pointer;
}

.menu{
    margin-right: 2%;
    margin-bottom: 8%;
    cursor: pointer;
}

.carte_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: none;
    background-color: #25b8d2;
    padding: 7% 25%;
    border-radius: 15px;
    color: #F6F6F6;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 17px;
    margin-top: 5%;
    font-weight: 600;

}