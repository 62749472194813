.ProSolutions1{
    background: linear-gradient(180deg, rgba(1, 48, 94, 1) 0%, rgba(0, 188, 212, 1) 97%, rgba(255, 255, 255, 1) 98%);
    padding-bottom: 0%;
}

.ProSolutions1 img {
    width: 100%; /* Takes the full viewport width */
    max-width: 100%; /* Ensures the image doesn’t exceed the screen width */
    height: auto; /* Maintains the image’s aspect ratio */
    padding-top: 5%;
    padding-bottom: 0;
    filter: brightness(60%);
    position: relative;
}

.ProSolutions1 h1 {
  padding-top: 20%;
  color: #f3f3f3;
  font-size: 56px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
z-index: 2;
}

.ProSolutions2_h1{
    color:#01305E;
    font-size: 36px;
    text-align: center;
    padding-top: 2%;
}

.ProSolutions2_h2{
    color:#01305E;
    font-size: 28px;
    text-align: center;
}

.ProSolutions2_2{
    display: flex;
    justify-content: center;
    gap: 1%;
    padding: 3% 10%;
    text-align: center;

}

.ProSolutions2_2 div{
    width: 24%;
}

.ProSolutions2_2 h1 {
    background-color: #01305E;
    height: 100px;
    font-size: clamp(16px, 2vw, 22px); /* Responsive font size with limits */
    color: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 3%; /* Ensures some spacing inside the h1 */
    overflow: hidden; /* Prevents text overflow */
    box-sizing: border-box; /* Includes padding in the height */
}


.ProSolutions2_2 p{
    background-color: #DADDE3;
    font-size: 20px;
    padding: 5%;
    margin-top: 0;
    color: #01305E;
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .nfc-uses {
        text-align: center;
        background: linear-gradient(to bottom, #1f4d82, #0d325b);
        color: #ffffff;
        padding: 5% 10%;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .nfc-uses h2 {
        font-size: 2.5em;
        margin-bottom: 20px;
      }
      
      .nfc-uses-container {
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        position: relative;
        max-width: 100%;
        padding-left: 10%;
        
      }
      
      .nfc-uses-container::before {
        content: "";
        position: absolute;
        left: 3%; 
        top: 0;
        bottom: 0;
        width: 4px;
        background-color: #ffffff;
      }
      
      .nfc-item {
        display: flex;
        align-items: center;
        margin: 20px 0;
        position: relative;
      }
      
      .nfc-icon {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        color: #1f4d82;
        font-size: 1.5em;
        border-radius: 50%;
        margin-right: 10px;
        flex-shrink: 0;
        position: relative;
      }

      .nfc-text {
        border-left-style: solid;
        text-align: left;
        padding-left: 3%;
      }
      
      .nfc-text h3 {
        font-size: 1.3em;
        margin: 0;
        color: #ffffff;
      }
      
      .nfc-text p {
        font-size: 1.1em;
        margin: 5px 0 0;
        color: #dcdcdc;
      }

      .nfc-advantages-container {
        text-align: center;
        padding: 0;
      }
      
      .nfc-advantages-header {
        background: linear-gradient(180deg, rgba(13, 50, 91, 1) 0%, rgba(0, 152, 212, 1) 100%);
        color: #ffffff;
        padding: 5% 0;
      }

      .nfc-advantages-header_content{
        background-color: #01305E;
        width: 35%;
        margin: auto;
        padding: 1.5%;
      }
      
      .nfc-advantages-header h1 {
        font-size: 28px;
        margin: 0;
      }
      
      .nfc-advantages-header p {
        font-size: 20px;
        margin: 5px 0 ;
      }
      
      .nfc-advantages-cards {
        display: flex;
        justify-content: space-around;
        margin: 5% 0;
        padding: 0% 10%;
      }
      
      .advantage-card {
        width: 30%;
        text-align: center;
      }
      
      .advantage-card img {
        width: 90px; /* Adjust size as needed */
        height: auto;
        margin-bottom: 5%;
      }
      
      .advantage-card p {
        font-size: 22px;
        font-weight: 500;
        color: #01305E;
      }

      .pro_bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .pro_bottom button {
        margin-bottom: 5%; 
        border: none;
        font-size: 36px;
        padding: 0.5% 3%;
        font-weight: 700;
        background-color: #01305E;
        color: #ffffff;
        cursor: pointer;
      }

      .pro_bottom div img {
        max-width: 100%; /* Take the full width of the viewport */
        height: auto; /* Keep aspect ratio */
        display: block;

  /* Mask gradient to fade left and right */
  -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
  mask-repeat: no-repeat;
  mask-size: cover;

  /* Ensures the image fits */
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .ProSolutions1 img {
    padding-top: 30%;
    padding-bottom: 0;
    filter: brightness(60%);
    position: relative;
}  
  
  .ProSolutions1 h1 {
        padding-top: 35%;
        font-size: 18px;
        text-align: center;
        font-weight: 700;
        text-wrap: nowrap;
    }
    
    .ProSolutions2_h1{
        font-size: 18px;
        padding-top: 5%;
    }
    
    .ProSolutions2_h2{
        font-size: 14px;
    }
    
    .ProSolutions2_2{
        flex-direction: column;
        gap: 1%;
        padding-top: 5%;
    }
    
    .ProSolutions2_2 div{
        width: 100%;
    }
    
    .ProSolutions2_2 h1 {
        height: 70px;
        font-size: clamp(14px, 2vw, 22px); /* Responsive font size with limits */
    }
    
    
    .ProSolutions2_2 p{
        font-size: 14px;
        }

        .nfc-uses {
            text-align: center;
            background: linear-gradient(to bottom, #1f4d82, #0d325b);
            color: #ffffff;
            padding: 3%;
            max-width: 100%;
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          
          .nfc-uses h2 {
            font-size: 1.5em;
            margin-bottom: 20px;
          }
          
          .nfc-uses-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            padding-left: 30px;
          }
          
          .nfc-uses-container::before {
            content: "";
            position: absolute;
            left: 55px; /* Center the line between icons */
            top: 0;
            bottom: 0;
            width: 4px;
            background-color: #ffffff;
          }
          
          .nfc-item {
            display: flex;
            align-items: center;
            margin: 20px 0;
            position: relative;
          }
          
          .nfc-icon {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffff;
            color: #1f4d82;
            font-size: 1.5em;
            border-radius: 50%;
            margin-right: 10px;
            flex-shrink: 0;
            position: relative;
          }
    
          .nfc-text {
            border-left-style: solid;
            text-align: left;
            padding-left: 3%;
          }
          
          .nfc-text h3 {
            font-size: 1.1em;
            margin: 0;
            color: #ffffff;
          }
          
          .nfc-text p {
            font-size: 0.9em;
            margin: 5px 0 0;
            color: #dcdcdc;
          }

          .nfc-advantages-container {
            text-align: center;
            padding: 0;
          }
          
          .nfc-advantages-header {
            background: linear-gradient(180deg, rgba(13, 50, 91, 1) 0%, rgba(0, 152, 212, 1) 100%);
            color: #ffffff;
            padding: 5% 0;
          }
    
          .nfc-advantages-header_content{
            background-color: #01305E;
            width: 80%;
            margin: auto;
            padding: 2%;
          }
          
          .nfc-advantages-header h1 {
            font-size: 20px;
            margin: 0;
          }
          
          .nfc-advantages-header p {
            font-size: 16px;
            margin: 5px 0 ;
          }
          
          .nfc-advantages-cards {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin-top: 5%;
            padding: 0% 7%;
            padding-right: 2%;
          }
          
          .advantage-card {
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 5%;
          }
          
          .advantage-card img {
            width: 70px; /* Adjust size as needed */
            height: auto;
            margin-bottom: 0;
          }
          
          .advantage-card p {
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            padding-left: 10%;
            color: #01305E;
          }
    
          .pro_bottom button {
            margin-bottom: 10%; 
            font-size: 26px;
            padding: 2% 7%;
          }
}