.Web_contact {
    background: url(./hero-bg.png);
    background-size: cover;
    padding: 7% 0px;
    border-radius: 0px;
    overflow: hidden;
    position: relative;
    color: white;
}

.Web_contact_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0% 15%;
    padding-bottom: 20%;
    color: white;
    position: relative;
    z-index: 2; /* Ensures content appears above overlay */
}

.Web_contact_content h1{
    font-size: 28px;
    margin-bottom: 10px;
    font-weight: 500;
}

.Web_contact_content h2{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.Web_contact_content button{
    font-size: 18px;
    font-weight: 500;
    color: rgb(245, 245, 245);
    border: none;
    background: linear-gradient(90deg, rgb(55, 95, 153) 0%, rgba(65, 190, 196, 1) 85%);
    border-radius: 30px;
    padding: 1.5% 3%;
    cursor: pointer;
    margin-top: 15px;
}

.Web_contact_content button:hover{
    background: linear-gradient(180deg, rgba(72, 154, 194, 1) 0%, rgba(65, 190, 196, 1) 65%);
}

@media (max-width: 768px) {
    .Web_contact_content {
        padding: 0% 10%;
        padding-bottom: 50%;
    }
    
    .Web_contact_content h1{
        font-size: 24px;
    }
    
    .Web_contact_content h2{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    
    .Web_contact_content button{
        font-size: 18px;
        font-weight: 500;
        color: rgb(245, 245, 245);
        border: none;
        background: linear-gradient(90deg, rgb(55, 95, 153) 0%, rgba(65, 190, 196, 1) 85%);
        border-radius: 30px;
        padding: 4% 7%;
        cursor: pointer;
        margin-top: 15px;
    }
}
