.VcfForm{
    background: linear-gradient(255deg, #489AC3 0%, #40C0C3 100%);
}

.welcome_popup{
    padding-bottom: 60% !important;
    padding: 12% 27%;
}

.welcome_popup_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3;
    padding: 5% 20%;
    text-align: center;
    border-radius: 15px;
}

.welcome_popup_h1 {
    flex-shrink: 0;
    flex-basis: auto;
    margin: 0;
    height: auto; /* Allow height to adjust based on content */
    font-family: "Montserrat";
    font-size: 36px;
    font-weight: 600;
    background: linear-gradient(90deg, #605ca9, #41BBC3);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    /* Allow text to wrap */
    white-space: normal; /* Remove nowrap to allow wrapping */
    word-wrap: break-word; /* Ensure long words break and wrap */
}

.welcome_popup_p{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}

.welcome_popup_button{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: 700;
    color: #f3f3f3;
    padding: 2% 10%;
    cursor: pointer;
    background: linear-gradient(90deg, #4b88c3, #605caa);
    border: none;
    border-radius: 10px;
    margin-top: 3%;
}

/* ----------- vcf page style ----------*/

.VCF_Page{
    padding: 7% 15%;
    padding-bottom: 25% ;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.VCF_Page_part1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.VCF_Page_part1_content{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding-left: 5%;
}

.VCF_Page_part1_content h2 {
    color: #f3f3f3;
    font-weight: 300;
    font-size: 2.2vw; /* Responsive font size */
    margin: 0;
}

.VCF_Page_part1_content h1 {
    color: #f3f3f3;
    font-weight: 700;
    font-size: 5.5vw; /* Responsive font size */
    margin: 0;
}

.VCF_Page_part1_content p {
    color: #f3f3f3;
    font-weight: 300;
    font-size: 2vw; /* Responsive font size */
    margin: 0;
    padding-right: 20%;
}

.VCF_Page_part2{
    background-color: #f3f3f3;
    border-radius: 10px;
    width: 80%;
    margin-top: 3%;
}

/* Media query for larger screens to set a maximum font size */
@media (min-width: 1600px) {
    .VCF_Page_part1_content h2 { 
        font-size: 30px; /* Fixed size for larger screens */
    }

    .VCF_Page_part1_content h1 {
        font-size: 106px; /* Fixed size for larger screens */
    }

    .VCF_Page_part1_content p {
        font-size: 30px; /* Fixed size for larger screens */
    }
}


.VCF_Page_part1_image {
    width: 100%;
    max-width: 550px;
    margin: 0 ; /* Center the image container */
}

.VCF_Page_part1_image img {
    width: 100%; /* Ensure the image takes the full width of the container */
    height: auto; /* Maintain the aspect ratio */
    display: block; /* Remove the default inline spacing below images */
}

@media (max-width: 1650px) {
    .welcome_popup{
        padding: 15% 20%;
    }
    .welcome_popup_container{
        padding: 5% 20%;
    }

    .VCF_Page_part2{
        width: 90%;
    }
}

@media (max-width: 1050px) {
    .welcome_popup{
        padding: 15% 10%;
    }

    .welcome_popup_container{
        padding: 5% 18%;
    }
    .VCF_Page{
        padding: 10% 5%;
        padding-bottom: 30%;
    }

    .VCF_Page_part2{
        width: 100%;
    }
}

@media (max-width: 750px) {
    .welcome_popup_h1 {
        font-size: 28px;
    }

    .welcome_popup_p{
        font-size: 18px;
    }

    .welcome_popup_button{
        font-size: 28px;
        padding: 5% 20%;
    }
    .welcome_popup{
        padding: 25% 5%;
    }

    .welcome_popup_container{
        padding: 5% 10%;
    }

    .VCF_Page{
        padding: 5%;
        padding-bottom: 60%;
    }

    .VCF_Page_part1_content{
        padding-left: 3%;
    }
    .VCF_Page_part1{
        padding-top: 20%;
    }

    .VCF_Page_part1_content h2 {
        font-size: 10px;
        margin-bottom: 3%;
    }
    
    .VCF_Page_part1_content p {
        font-size: 12px; /* Responsive font size */
        padding-right: 0;
        margin-top: 3%;
    }
}