#FAQ{
    width: 100%;
    margin: 0;
    padding-top: 5%;
    padding-bottom: 47%;
    background: linear-gradient(180deg, rgba(243, 243, 243, 1) 5%, rgb(55, 95, 153) 60%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#title{
    font-size: 22px;
    font-weight:500;
    color: #1EA8C2;
    margin-bottom: 8%;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}
.typo{
    background-color:rgb(240, 240, 240);
    color: rgb(120, 120, 120);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
}

.FAQ_container{
    padding: 0 5%;
}

@media screen and (min-width: 768px) {
    #FAQ{
        width: 100%;
        margin: 0;
        padding-top: 3%;
        padding-bottom: 30%;
        text-align: center;
    }
    #title{
        font-size: 33px;
        font-weight:500;
        color: #3C88BB;
        margin-bottom: 5%;
        text-align: center;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-align: center;
    
    }
    .typo{
        background-color:rgb(240, 240, 240);
        color: rgb(120, 120, 120);
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        
    }

    .FAQ_container{
        padding: 0 15%;
    }
}