/* Styles for small devices */
.partie_1_faq {
  background: linear-gradient(180deg, rgba(55, 95, 153, 1) 0%, rgba(48, 170, 199, 1) 48%, rgba(243, 243, 243, 1) 83%);
    padding-top: 30%;
  }
  
  .partie_1_top_faq {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  
  .text-content_faq {
    position: absolute;
    z-index: 1;
    left: 7%;
    margin-right: 40%;
  }

   .faq_icon{
        width: 40px;
        margin-left: 2%;
    }
  
    .partie_1_top_faq h1 {
        color: #f8f8f8;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 400;
        font-size: 13px;
        margin-bottom: 0;
        text-align: left;
        line-height: 20px;
      }
  
  .image-container_faq {
    flex: 1;
    text-align: right;
    padding-right: 0%;
  }
  
  .faq_img {
    width: 180px;
    animation: upDownAnimation 4.2s ease infinite; /* Apply animation */
  }
  
  @keyframes upDownAnimation {
    0% {
      transform: translateY(15px); /* Start position */
    }
    50% {
      transform: translateY(0px); /* Midway position, move up */
    }
    100% {
      transform: translateY(15px); /* End position, return to original */
    }
  }
  
  .waves_faq {
    position: relative;
    width: 100%;
    height: 100px;
    margin-top: 15%;
    padding-bottom: 8%;
  }
  
  .waves_faq img {
    position: absolute;
    width: 100%;
  }
  
  .waves_1_1_faq {
    height: 100px;
    filter: brightness(220%);
    animation: waveAnimation1_fqa 2s ease-in-out infinite alternate;
    z-index: 1;
}

.waves_2_1_faq {
    height: 100px;
    padding-top: 10px;
    filter: brightness(120%);
    animation: waveAnimation2_fqa 2s ease-in-out infinite alternate;
    z-index: 1;
}

.waves_3_1_faq {
    height: 100px;
    padding-top: 50px;
    filter: brightness(100%);
    animation: waveAnimation3_fqa 2s ease-in-out infinite alternate;
    z-index: 1;
}

.waves_4_1_faq {
    height: 100px;
    padding-top: 20px;
    filter: brightness(135%);
    animation: waveAnimation4_fqa 2s ease-in-out infinite alternate;
    z-index: 1;
}

@keyframes waveAnimation1_fqa {
    0% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-40px);
    }
}

@keyframes waveAnimation2_fqa {
    0% {
        transform: translateY(-30px);
    }
    100% {
        transform: translateY(-5px);
    }
}

@keyframes waveAnimation3_fqa {
    0% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(-30px);
    }
}

@keyframes waveAnimation4_fqa {
    0% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(-30px);
    }
}
  
  @media screen and (min-width: 768px) {
    .partie_1_faq {
      padding-top: 100px;
    }
    
    .partie_1_top_faq {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }
    
    .text-content_faq {
      position: absolute;
      z-index: 1;
      left: 7%;
      margin-right: 40%;
    }

    .faq_icon{
        width: 70px;
        margin-left: 2%;
    }
    
    .partie_1_top_faq h1 {
      color: white;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 400;
      font-size: 30px;
      margin-bottom: 0;
      text-align: left;
      line-height: 48px;
      width: 90%;
    }
    
    .image-container_faq {
      flex: 1;
      text-align: right;
      padding-right: 0;
    }
    
    .faq_img {
      width: 430px;
      animation: upDownAnimation 4s ease infinite; /* Apply animation */
    }
    
    @keyframes upDownAnimation {
      0% {
        transform: translateY(-15px); /* Start position */
      }
      50% {
        transform: translateY(-45px); /* Midway position, move up */
      }
      100% {
        transform: translateY(-15px); /* End position, return to original */
      }
    }
    
    .waves_faq {
        position: relative;
        width: 100%;
        height: 100px;
        margin-top: 0;
        margin-bottom: 0;
      }
  }
  