.QR img {
	display: block;
	width: 350px;
	height: 350px;
	margin: 2rem auto;
	max-width: 100%; /* Make sure the image doesn't exceed its container */
  }
  
  .QR {
	padding: 2rem;
    text-align: center;
    background: linear-gradient(0deg, rgba(112, 190, 240, 1) 0%, rgba(19, 165, 188, 1) 100%);
    padding-bottom: 44%;
  }
  
  .QR h1 {
	font-size: 2.3rem;
	margin-bottom: 1rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(16, 92, 146);

  }
  
  .QR input {
	appearance: none;
	outline: none;
	border: none;
	background: #eeeeee;
	width: 100%;
	max-width: 320px;
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	margin-right: 1rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .QR button,
  .QR a {
	appearance: none;
	outline: none;
	border: none;
	background: none;
	cursor: pointer;
	color: #f5f5f5;
	font-size: 1.5rem;
	text-decoration: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  /* Responsive styles */
  @media only screen and (max-width: 600px) {
	.QR img {
	  width: 100%; /* Make the image take the full width of the container on small screens */
	  height: auto; /* Maintain the aspect ratio */
	}
  
	.QR {
	  padding: 1rem;
	}
  
	.QR input {
	  max-width: 100%; /* Make the input take the full width on small screens */
	  margin-right: 0;
	  margin-bottom: 1rem;
	}
  }
  