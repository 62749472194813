.cardBody{
    background-color: white;
    padding: 0% 3%;
}

.profilePic{
    width: 125px;
    height: 125px;
    border-radius: 100%;
}

.topCard{
padding-top: 50px;
width: 100%;
}

.topCard1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10%;
    margin-left: 5%;
    width: 90%;
}

.profileName {
    font-weight: 700;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-top-style: solid;
    border-top-width: 4px;
    border-bottom-style: solid;
    border-bottom-width: 4px;
    height: 115px;
    line-height: 230%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-overflow: ellipsis; /* Display ellipsis (...) for overflow */
    overflow: hidden; /* Hide the overflow content */
    white-space: nowrap; /* Prevent text from wrapping */
}

.topCard2{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: left;
    margin-left: 10%;
    margin-top: 8%;
    font-size: 18px;
    font-style: italic;
}

.middleCard{
    padding-top: 10%;
}

.mapField{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #A6A6A6;
    padding-right: 2%;
    cursor: pointer;
}

.mapFieldClicked{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #A6A6A6;
    background-color: #F6F6F6;
    padding-right: 2%;
    cursor: pointer;
}

.mapFieldFinal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #A6A6A6;
    padding-right: 2%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #A6A6A6;
    cursor: pointer;
}

.mapFieldFinalClicked{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F6F6F6;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #A6A6A6;
    padding-right: 2%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #A6A6A6;
    cursor: pointer;
}

.mapField1{
    display: flex;
    align-items: center;
    margin-left: 7%;
}

h4{
    font-size: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-left: 10px;
}

.logoImg{
    width: 170px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 5px;
}

.logo{
    background-color: #f5f5f5;
}

.bottom_links{
    display: flex;
    justify-content: center;
    padding-left: 20%;
    padding-right: 20%;
    align-items: center;
    background-color: #fff;
}
.bottom_links a{
    text-decoration: none;
    color: black;
    margin: 0% 2%;
}

.poweredBy{
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    padding: 0rem 3.5rem;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.poweredBy button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.2rem;
    border-style: none;
    padding: 12px 10px;
    background-color: rgb(116, 116, 116);
    border-radius: 10px;
    color: #F6F6F6;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 1rem;

    /* Shadow and 3D effect */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(255, 255, 255, 0.2) inset;
    transition: transform 0.1s ease-in-out;
}


.poweredBy img{
    width: 210px;
    margin: 4% 0;
}
