.ContactForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0rem 4%;
    margin-right: 0;
    background-color: #F8F8F8;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 6%;
    border-radius: 10px;
    width: 90%;
}

.ContactForm_1{
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 5% 2%;
    padding-bottom: 0;
}

.Label{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    color: #45B2D8;
    font-weight: 400;
    margin-bottom: 15px;
}

.InputField{
    border-style: solid;
    border-width: 1.5px;
    border-color: #45B2D8;
    margin-bottom: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    color: rgb(59, 59, 59);
    font-weight: 500;
    padding: 7px 7px;
}

.InputField:focus {
    outline: none;
    border: 2px solid #289fc7;
  }

.InputField::placeholder{
    color: rgb(182, 182, 182);
    font-size: 14px;
}
.FormButton{
    margin: auto;
    margin-bottom: 0%;
    font-size: 26px;
    font-weight: 700;
    color: rgb(245, 245, 245);
    border: none;
    background: linear-gradient(90deg, rgba(72, 154, 194, 1) 0%, rgba(65, 190, 196, 1) 65%);
    border-radius: 18px;
    padding: 2% 10%;
    margin-top: 3%;
    cursor: pointer;
}

.FormButton:hover{
    background: linear-gradient(180deg, rgba(72, 154, 194, 1) 0%, rgba(65, 190, 196, 1) 65%);
}

.fleche{
    transform: rotate(-25.75deg);
    margin: 0;
    width: 150px;
}

.ContactForm-23{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: rgb(212, 212, 212);
    width: 90%;
}

.ContactForm-23 h3{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 24px;
    color: #45B2D8;
    font-weight: 300;
    margin: 5%;
}

.ContactForm-23 h2{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 36px;
    color: #45B2D8;
    font-weight: 500;
    margin: 2%;
    margin-top: 5%;
    text-align: center;
}

.ContactImg {
    max-width: 100%;
    height: auto;
  }

  @media screen and (max-width: 480px) {
    .ContactForm-23 h2{
        font-size: 26px;
    }

    .ContactForm{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0rem 5%;
        background-color: #F8F8F8;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 6%;
        border-radius: 10px;
        width: 90%;
    }

    .FormButton{
        font-size: 16px;
        border-radius: 18px;
        padding: 5% 20%;
        margin-top: 10%;
    }
  }