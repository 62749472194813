.ContactUs{
  background: linear-gradient(111deg, rgba(96, 92, 169, 1) 0%, rgba(72, 154, 194, 1) 35%, rgba(65, 190, 196, 1) 73%, rgba(66, 221, 229, 1) 100%);}

.ContactUs_Content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10%;
    padding-bottom: 50%;
}

@media screen and (min-width: 768px) {
    .ContactUs_Content {
      flex-direction: row-reverse;
      padding-top: 10%;
      padding-bottom: 20%;
    }
  
    .ContactTop, .ContactForm {
      width: 45%; 
    }
  }
  
  .ContactTop, .ContactForm {
    width: 100%;
    margin-bottom: 20px;
  }