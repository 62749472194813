.la_nfc{
    background: linear-gradient(255deg, #489AC3 0%, #40C0C3 100%);
    color: white;
    width: 100%;
}

.lanfc_top{
display: flex;
align-items: center;
padding: 10%;
font-family: "montserrat";
padding-bottom: 3%;
}

.lanfc_top1 h1{
    font-size: 64px;
    line-height: 30%;
}
.lanfc_top1 h2{
    font-size: 44px;
    line-height: 50%;
    font-weight: 500;
    word-spacing: 5px;
    letter-spacing: 1px;
}

.lanfc_top1 p{
    font-size: 24px;
    font-weight: 300;
    padding-right: 30%;
}
.lanfc_top2 img {
    width: 100%;          /* Make the image width 100% of its container */
    max-width: 100%;      /* Ensure the image never exceeds 100% of the screen */
    height: auto;         /* Maintain the aspect ratio */
    display: block;       /* Remove any extra space below the image */
    margin: 0 auto;       /* Center the image horizontally if needed */
}

.lanfc_mid{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.lanfc_mid img {
    width: 100%;          /* Make the image width 100% of its container */
    max-width: 100%;      /* Ensure the image never exceeds 100% of the screen */
    height: auto;         /* Maintain the aspect ratio */
    display: block;       /* Remove any extra space below the image */
    margin: 0 auto;       /* Center the image horizontally if needed */
}

.lanfc_wave img{
    width: 100%;          /* Make the image width 100% of its container */
    max-width: 100%;      /* Ensure the image never exceeds 100% of the screen */
    height: auto;         /* Maintain the aspect ratio */
    display: block;       /* Remove any extra space below the image */
    margin: 0 auto;      /* Center the image horizontally if needed */
}

.lanfc_wave {
    margin-top: -5%;
    position: relative; /* Ensure proper stacking context */
    z-index: 1; /* Lower z-index */
}

.lanfc_mid2 {
    background-color: #f8f8f8;
    margin-top: -10%;
    position: relative; /* Ensure proper stacking context */
    z-index: 2; /* Higher z-index to appear in front of .lanfc_wave */
}

.lanfc_mid2_text{
    padding: 0% 25%;
    text-align: center;
}
.lanfc_mid2_text h1{
    font-size: 54px;
    font-weight: 500;
    color: black;
}

.lanfc_mid2_text p{
    font-size: 32px;
    font-weight: 300;
    color: black;
}

.lanfc_mid2_img {
    display: flex;
    justify-content: center;
}

.lanfc_mid2_img img{
    width: 45%;          /* Make the image width 100% of its container */
    max-width: 100%;      /* Ensure the image never exceeds 100% of the screen */
    height: auto;         /* Maintain the aspect ratio */
    display: block;       /* Remove any extra space below the image */
}

.lanfc_bottom1 {
    margin-top: 5%;
    text-align: center;
    padding: 0% 25%;
}

.lanfc_bottom h1{
    font-size: 54px;
    font-weight: 500;
}

.lanfc_bottom p{
    font-size: 30px;
    font-weight: 300;
}

.lanfc_bottom2{
    display: flex;
    justify-content: space-between;
    padding:5% 10%;
    padding-bottom: 30%;
}

.lanfc_bottom1_carte{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FEFEFE;
    box-shadow: 1px 1px 15px -6px rgba(0, 0, 0, 0.68);
    border-radius: 12px;
    width: 30%; /* Each div takes 30% of the container's width */
    margin: 0 2%;
}

.lanfc_bottom1_carte h1{
    font-size: 24px;
    color: #489AC3;
    font-weight: 700;
    margin-top: 2%;
}

.lanfc_bottom1_carte p{
    text-align: center;
    font-size: 20px;
    color:rgb(107, 107, 107);
    font-weight: 500;
    padding: 0% 10%;
}


@media screen and (max-width: 768px) {
    .lanfc_top1 h1{
        font-size: 34px;
        line-height: 50%;
        padding-top: 40%;
    }
    .lanfc_top1 h2{
        font-size: 26px;
        line-height: 100%;
        font-weight: 500;
        word-spacing: 5px;
        letter-spacing: 1px;
    }
    
    .lanfc_top1 p{
        font-size: 16px;
        font-weight: 300;
        padding-right: 10%;
    }

    .lanfc_mid2 {
        background-color: #f8f8f8;
        margin-top: -11%;
        position: relative; /* Ensure proper stacking context */
        z-index: 2; /* Higher z-index to appear in front of .lanfc_wave */
    }

    .lanfc_mid2_text{
        padding: 0% 10%;
        text-align: center;
    }

    .lanfc_mid2_text h1{
        font-size: 32px;
        font-weight: 500;
        padding-right: 00%;
        color: black;
        padding-top: 5%;
    }
    
    .lanfc_mid2_text p{
        font-size: 20px;
        font-weight: 300;
        padding: 0%;
    }

    .lanfc_bottom1 {
        margin-top: 5%;
        text-align: center;
        padding: 0% 10%;
    }

    .lanfc_bottom {
        margin-top: 10%;
        padding: 0% 0%;
        padding-bottom: 60%;
    }

    .lanfc_bottom h1{
        font-size: 30px;
        text-align: center;
    }
    
    .lanfc_bottom p{
        font-size: 20px;
    }

    .lanfc_bottom2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0;
        padding-bottom: 0;
    }
    
    .lanfc_bottom1_carte{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #FEFEFE;
        box-shadow: 1px 5px 10px -2px rgba(0, 0, 0, 0.68);
        border-radius: 12px;
        width: 70%; /* Each div takes 30% of the container's width */
        margin: 3% 0%;
        padding: 2%;
    }
    
    .lanfc_bottom1_carte h1{
        font-size: 20px;
        color: #489AC3;
        font-weight: 700;
        margin-top: 2%;
    }
    
    .lanfc_bottom1_carte p{
        text-align: center;
        font-size: 16px;
        color:rgb(107, 107, 107);
        font-weight: 500;
        padding: 0% 10%;
    }
    
}