.wrapper {
    position: fixed;
    width: 100%;
    box-shadow: 0px 9px 10px #0000002b, -2px -2px 2px #ffffff;
    background-color: #f3f3f3;
    z-index: 99;
    top: 0;
  }
  
  .wrapper nav {
    position: relative;
    display: flex;
    max-width: calc(100% - 400px);
    margin: 0 auto;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    padding: 0% 15%;
  }
  
  
  nav .nav-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  nav .nav-content .links {
    margin-left: 80px;
    display: flex;
    gap: 10px;
    white-space: nowrap; /* Prevent wrapping */
    margin: 0 ;
  }
  
  .nav-content .Navbarlogo {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 70px;
    background-color: transparent;
    overflow: hidden; /* Hide overflow */
  }
  
  .nav-content .Navbarlogo img {
    height: 70%; /* Make sure the image scales to the height of the logo container */
    width: auto; /* Maintain aspect ratio */
    object-fit: contain;
    cursor: pointer;
  }
  
  .nav-content .links li {
    list-style: none;
    line-height: 70px;
  }
  
  .nav-content .links li a,
  .nav-content .links li label {
    color: #000;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    padding: 7px 14px;
    border-radius: 5px;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .nav-content .links li label {
    display: none;
  }
  
  .nav-content .links li a:hover,
  .nav-content .links li label:hover {
    color: #40C0C3;
  }

  .search-icon{
    display:flex;
  }
  
  .wrapper .search-icon,
  .wrapper .menu-icon {
    color: #000;
    font-size: 18px;
    cursor: pointer;
    line-height: 70px;
    width: 70px;
    text-align: center;
  }
  
  .wrapper .menu-icon {
    display: none;
  }
  
  .wrapper input[type="checkbox"] {
    display: none;
  }
  
  /* Dropdown Menu code start */
  .nav-content .links ul {
    position: absolute;
    background: #F3F3F3;
    top: 80px;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    color: #202020;
    padding-left: 2%;
    padding-right: 15%;
    padding-bottom: 1%;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .links ul h1 {
    margin: 0;
    font-family: "montserrat";
    font-size: 20px;
    font-weight: 700;
    line-height: 50px;
    cursor: pointer;
  }
  
  .nav-content .links li:hover > ul {
    top: 70px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
  }
  
  .nav-content .links ul li a {
    color: #010101;
    display: block;
    width: 100%;
    line-height: 20px;
    text-align: left;
    font-family: "montserrat";
    font-size: 18px;
    font-weight: 500;
  }

  .nav-content .links ul li a:hover {
    border-left-style: solid;
    border-left-width: 4px;
    border-left-color: #605caa;
    border-radius: 0;
    padding-left: 2%;
  }
  
  .nav-content .links ul ul {
    position: absolute;
    top: 0;
    right: calc(-100% + 8px);
  }
  
  .nav-content .links ul li {
    position: relative;
  }
  
  .nav-content .links ul li:hover ul {
    top: 0;
  }
  
  
  /* Responsive code start */
  @media screen and (max-width: 1600px) {
    .wrapper nav {
      max-width: 100%;
      padding: 0 100px;
    }
  
    nav .nav-content .links {
      margin-left: 30px;
    }

    .links ul h1 {
      font-size: 18px;
      line-height: 40px;
      color: #878787;
    }
  
    .nav-content .links li a {
      color: #000;
      padding: 8px 13px;
      font-size: 17px;
    }

    .search-icon{
      padding-right: 5%;
    }

    .Navbarlogo{
      padding-left: 3%;
    }
  }
  
  @media screen and (max-width: 1095px) {
    .wrapper nav {
      padding: 0 10px;
    }
    
    .wrapper .menu-icon {
      display: block;
    }
  
    .nav-content .Navbarlogo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .Navbarlogo img {
      width: 100px;
      height: 70px;
    }

    .search-icon{
      padding-right: 20%;
    }
  
    .wrapper #show-menu:checked ~ .menu-icon i::before {
      content: "\f00d";
    }
  
    nav .nav-content .links {
      display: block;
      position: fixed;
      background: #14181fc0;
      top: 70px;
      left: -100%;
      margin-left: 0;
      max-width: 350px;
      overflow-y: auto;
      transition: all 0.3s ease;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  
    nav #show-menu:checked ~ .nav-content .links {
      left: 0%;
    }
  
    .nav-content .links li {
      margin: 15px 20px;
    }
  
    .nav-content .links li a,
    .nav-content .links li label {
      line-height: 20px;
      font-size: 17px;
      display: block;
      padding: 8px 18px;
      cursor: pointer;
      color: #fff;
    }
  
    .nav-content .links li a.desktop-link {
      display: none;
    }
  
    /* dropdown responsive code start */
    .nav-content .links ul,
    .nav-content .links ul ul {
      position: static;
      opacity: 1;
      visibility: visible;
      background: none;
      max-height: 0px;
      overflow: hidden;
    }
  
    .nav-content .links #show-features:checked ~ ul,
    .nav-content .links #show-services:checked ~ ul,
    .nav-content .links #show-items:checked ~ ul {
      max-height: 100vh;
    }
  
    .nav-content .links ul li {
      margin: 7px 20px;
    }
  
    .nav-content .links ul li a {
      font-size: 15px;
      line-height: 15px;
      color: #f3f3f3;
      padding-left: 0;
    }

    nav .nav-content {
        width: auto;
    }

    .francais{
      display: none;
    }
  }
  
  @media screen and (max-width: 400px) {
    .wrapper nav {
      padding: 0 10px;
    }
.links {
      margin-left: 80px;
      display: flex;
      gap: 10px;
      white-space: nowrap; /* Prevent wrapping */
      margin: 0;
      height: 300px; /* Set fixed height */
      overflow-y: auto; /* Enable vertical scrolling */
    }
  
    .nav-content .Navbarlogo img {
      width: 100px;
      height: 70px;
    }
  
    .nav-content .Navbarlogo a {
      font-size: 23px;
    }
  }
  