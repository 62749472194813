.why-choose-us {
    text-align: center;
    padding: 2rem 1.5rem;
  }
  
  .why-choose-us h2 {
    font-size: 2rem;
    font-weight: 500;
    color: #025add;
    margin-bottom: 0.5rem;
  }

  .why-choose-us p {
    font-size: 1.1rem;
    color: #b8651d;
    margin-bottom: 1rem;
    margin-top: 0;
    font-weight: 500;
  }
  
  .why-choose-us-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 80%;
    margin: 0 auto;
    margin-top: 5%;
  }
  
  .why-choose-item {
    flex: 1 1 15%;
    text-align: center;
    max-width: 350px;
    display: flex;
    height: 150px;
  }

  .why-choose-item2{
    flex: 1 1 15%;
    text-align: center;
    max-width: 400px;
    display: flex;
    height: 150px;
  }

  .why-choose-item2 div {
    width: 80%;
    text-align: left;
  }

  .why-choose-item div {
    width: 80%;
    text-align: right;
  }
  .why-choose-item div h3{
    margin: 2% 0;
  }

  .why-choose-item2 div h3{
    margin: 2% 0;
  }
  
  
  .choose_us_icon {
    width: 52px;
    height: 52px;
    color: #025ADD;
    margin-left : 5%;
  }

  .choose_us_icon2{
    width: 52px;
    height: 52px;
    color: #025ADD;
    margin-right : 5%;
  }
  
  .center-image {
    text-align: center;
  }
  
  .center-image img {
    width: 100%;
    max-width: 300px;
  }
  
  .why-choose-item h3 {
    font-size: 1.5rem;
    color: #3a3a3a;
    font-weight: 500;
  }
  
  .why-choose-item p{
    color: #3a3a3a;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }

  .why-choose-item2 p{
    color: #3a3a3a;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }

  .why-choose-item2 h3 {
    font-size: 1.5rem;
    color: #3a3a3a;
    font-weight: 500;
  }

  @media (max-width: 768px) {

    .why-choose-us h2 {
      font-size: 1.5rem;
    }
  
    .why-choose-us p {
      font-size: 1rem;
      margin-bottom: 15%;
    }

    .why-choose-item {
      text-align: center;
      max-width: 100%;
      height: 190px;
    }
  
    .why-choose-item2{
      max-width: 100%;
      height: 190px;
    }

  }
  