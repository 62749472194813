/* Styles for small devices */
.partie_1 {
  background: linear-gradient(0deg, rgba(112, 190, 240, 1) 0%, rgba(19, 165, 188, 1) 100%);
  padding-top: 20%;
}

.partie_1_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.text-content {
  position: absolute;
  z-index: 1;
  left: 7%;
  margin-right: 50%;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s, transform 1s;
}

.animated {
  opacity: 1;
  transform: translateX(0);
}

.partie_1_top h1 {
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0;
}

.partie_1_top h2 {
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  font-size: 15px;
  margin-top: 5px;
  font-style: italic;
  margin-bottom: 5px;
  word-spacing: 1px;
}

.partie_1_top p {
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
  margin-top: 0;
  width: 115%;
  font-weight: 300;
  margin-bottom: 15px;
}

.partie_1_top button {
  color: #CF4D9C;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
  font-weight: 700;
  background-color: rgb(246, 247, 247);
  border-style: none;
  border-radius: 6px;
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  flex: 1;
  text-align: right;
  padding-right: 3%;
}

.partie_1_top img {
  width: 150px;
  animation: upDownAnimation 4.0s ease infinite; /* Apply animation */
}

@keyframes upDownAnimation {
  0% {
    transform: translateY(0px); /* Start position */
  }
  50% {
    transform: translateY(-25px); /* Midway position, move up */
  }
  100% {
    transform: translateY(0px); /* End position, return to original */
  }
}

.waves {
  position: relative;
  width: 100%;
  height: 100px;
}

.waves img {
  position: absolute;
  width: 100%;
}

@keyframes waveAnimation1 {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-10px);
  }
}

@keyframes waveAnimation2 {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-5px);
  }
}

@keyframes waveAnimation3 {
  0% {
      transform: translateY(5px);
  }
  100% {
      transform: translateY(10px);
  }
}

@keyframes waveAnimation4 {
  0% {
      transform: translateY(5px);
  }
  100% {
      transform: translateY(-5px);
  }
}

.waves_1_1 {
  height: 100px;
  filter: brightness(220%);
}

.waves_2_1 {
  height: 100px;
  padding-top: 30px;
  filter: brightness(120%);
}

.waves_3_1 {
  height: 100px;
  padding-top: 50px;
  filter: brightness(102%);
}

.waves_4_1 {
  height: 100px;
  padding-top: 30px;
  filter: brightness(145%);
}

@media screen and (min-width: 768px) {
  .partie_1 {
    background: linear-gradient(0deg, rgba(112, 190, 240, 1) 0%, rgba(19, 165, 188, 1) 100%);
    padding-top: 90px;
  }
  
  .partie_1_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 10% ;
  }
  
  .text-content {
    position:relative;
    z-index: 1;
    left: 0%;
    margin-right: 0%;
    width: 50%;
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.5s, transform 1s;
  }

  .animated {
    opacity: 1;
    transform: translateX(0);
  }
  
  .partie_1_top h1 {
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;
    font-size: 70px;
    margin-bottom: 0;
  }
  
  .partie_1_top h2 {
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 36px;
    margin-top: 5px;
    font-style: italic;
    margin-bottom: 5px;
    word-spacing: 1px;
  }
  
  .partie_1_top p {
    color: rgb(255, 255, 255);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 25px;
    margin-top: 0;
    width: 115%;
    font-weight: 300;
    margin-bottom: 30px;
  }
  
  .partie_1_top button {
    color: #CF4D9C;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 700;
    background-color: rgb(246, 247, 247);
    border-style: none;
    border-radius: 10px;
    padding: 16px 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 1%;
  }

  .partie_1_top button:hover {
    background-color: rgb(246, 247, 247);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
  }
  
  .image-container {
    flex: 1;
    text-align: right;
    padding-right: 0%;
  }
  
  .partie_1_top img {
    width: 50%;
    animation: upDownAnimation 4s ease infinite; /* Apply animation */
  }
  
  @keyframes upDownAnimation {
    0% {
      transform: translateY(5px); /* Start position */
    }
    50% {
      transform: translateY(-25px); /* Midway position, move up */
    }
    100% {
      transform: translateY(5px); /* End position, return to original */
    }
  }
  
  .waves {
    position: relative;
    width: 100%;
    height: 150px;
    margin-top:-40px;
  }
  
  .waves img {
    position: absolute;
    width: 100%;
  }
  
  @keyframes waveAnimation1 {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
  }
  
  @keyframes waveAnimation2 {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-5px);
    }
  }
  
  @keyframes waveAnimation3 {
    0% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(10px);
    }
  }
  
  @keyframes waveAnimation4 {
    0% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(-5px);
    }
  }
  
  .waves_1_1 {
    height: 150px;
    filter: brightness(220%);
  }
  
  .waves_2_1 {
    height: 150px;
    padding-top: 50px;
    filter: brightness(120%);
  }
  
  .waves_3_1 {
    height: 150px;
    padding-top: 70px;
    filter: brightness(102%);
  }
  
  .waves_4_1 {
    height: 150px;
    padding-top: 50px;
    filter: brightness(155%);
  }
}
