.CartePerso {
    background-image: url('https://i.imgur.com/Cluj4UU.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.hamburger{
    width: 50px;
    margin-top: 15%;
    margin-left: 8%;
}

.Perso_Profile_Pic_container {
    display: flex;
    justify-content: center;
  }
  
  .Perso_Profile_Pic {
    width: 160px;
    height: 160px;
    border-radius: 50%;
  }

  .CartePerso h1{
    font-size: 30px;
    text-align: center;
    color: #f3f3f3;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}

.perso_icons_container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 18%;
}

.perso_icons_container img{
    width: 30px;
}

.perso_buttons_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.perso_buttons_container button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 22px;
    background-color: transparent;
    padding: 2% 8%;
    color: #f3f3f3;
    border-style: solid;
    border-color: #f3f3f3;
    border-width: 2.5px;
    border-radius: 25px;
}

.perso_logo_container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}
.perso_logo{
    width: 220px;
}
