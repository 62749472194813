.Partie_4{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    margin-top: -45px;
    position: relative;
    width: 100%;
}

.Partie_4_imageContainer {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.Partie_4 img{
    width: 80%;
    padding: 0 ;
}

.Partie_4 h1{
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 23px;
    padding: 0 20%;
    line-height: 30px;
    font-weight: 700;
    color: rgb(37, 37, 37);
    margin: 0;
}

.Partie_4 h2{
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: rgb(37, 37, 37);
    margin-top: 1%;
    font-style: italic;
}

.Partie_4 p{
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: rgb(32, 32, 32);
    padding: 0 13%;
}

.button{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: rgb(245, 245, 245);
    border: none;
    background: linear-gradient(0deg, rgba(122, 197, 229, 1) 0%, rgba(47, 183, 199, 1) 100%);
    border-radius: 18px;
    padding: 15px 25px;
    margin-top: 3%;
    margin-bottom: 10%;
}

@media screen and (min-width: 768px) {
    .Partie_4{
        margin-top: -15%;
    }
    
    .Partie_4_imageContainer {
        width: 60%;
        align-self: center;
        margin-left: 0%;
    }
    
    .Partie_4 img{
        width: 100%;
        padding: 0 ;
    }
    
    .Partie_4 h1{
        text-align: center;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 34px;
        padding: 0 20%;
        line-height: 30px;
        font-weight: 700;
        color: #555555;
        margin: 0;
    }
    
    .Partie_4 h2{
        text-align: center;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 26px;
        font-weight: 400;
        color: #686868;
        margin-top: 1%;
        font-style: italic;
        margin-bottom: 2%;
    }
    
    .Partie_4 p{
        text-align: center;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #686868;
        padding: 0 20%;
        margin-top: 2%;
    }
    
    .button{
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 20px;
        font-weight: 700;
        color: rgb(245, 245, 245);
        border: none;
        background: linear-gradient(0deg, rgba(122, 197, 229, 1) 0%, rgba(47, 183, 199, 1) 100%);
        border-radius: 18px;
        padding: 15px 25px;
        margin-top: 1%;
        margin-bottom: 0;
    }
}