.Partie_2{
    background-color: #F8F8F8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 1;
    position: relative;
}

.Partie_2_1 div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FEFEFE;
    margin-top: 1rem;
    margin-left: 2rem;
    box-shadow: 1px 1px 15px -6px rgba(0,0,0,0.68);
    border-radius: 12px;
    padding: 10%;
    width: 100%;
}
.Partie_2_1{
opacity: 0;
transform: translateX(-100%);
transition: opacity 0.5s, transform 1s;
}

.animatedPartie_2_1 {
opacity: 1;
transform: translateX(0);
}

.speed{
    width: 40px;
}

.digital{
    width: 40px;
}

.nfc{
    width: 60px;
}

.Partie_2_1 div h1{
    font-size: 11px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #45b2d8;
    font-weight: 700;
    margin: 0;
}

.Partie_2_1 div p{
    font-size: 8px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(41, 41, 41);
    font-weight: 600;
    margin-top: 4px;
    margin-bottom: 0;
    text-align: center;
}

.Partie_2_2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 22%;
    height: 450px;
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s, transform 1s;
    }
    
    .animatedPartie_2_2 {
    opacity: 1;
    transform: translateX(0);
    }

.Partie_2_2 img{
    width: 100%;
    margin-right: 10%;
}
.Partie_2_2 h1{
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #45b2d8;
    font-weight: 700;
    margin: 0;
    padding-right: 5%;
}

.Partie_2_2 p{
    font-size: 14px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #2f2f2f;
    font-weight: 500;
    margin-top: 10px;
    padding-bottom: 15%;
    padding-right: 6%;
}

@media screen and (min-width: 768px) {
    .Partie_2{
        background-color: #F8F8F8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: auto;
        z-index: 1;
        position: relative;
        padding-top: 30px;
        padding-bottom: 3%;
        padding: 0 10%;
    }
    
    .Partie_2_1 div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #FEFEFE;
        margin: 0;
        margin-bottom: 5%;
        box-shadow: 1px 1px 15px -6px rgba(41, 38, 38, 0.68);
        border-radius: 12px;
        padding: 7% 0%;
        width: 70%;
    }

    .Partie_2_1 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        opacity: 0;
        transform: translateX(-100%);
        transition: opacity 0.5s, transform 1s;
      }
    
      .animatedPartie_2_1 {
        opacity: 1;
        transform: translateX(0);
      }

    .speed{
        width: 60px;
    }
    
    .digital{
        width: 50px;
    }
    
    .nfc{
        width: 70px;
    }
    
    .Partie_2_1 div h1{
        font-size: 20px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        color: #45b2d8;
        font-weight: 700;
        margin: 0;
    }
    
    .Partie_2_1 div p{
        font-size: 15px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        color: #686868;
        font-weight: 600;
        margin-top: 2%;
        margin-bottom: 0;
        text-align: center;
        width: 90%;
    }
    
    .Partie_2_2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0;
        height: 705px;
        width: auto;
    }
    
    .Partie_2_2 img{
        width: 60%;
        margin-right: 0;
        margin-top: 2%;
    }
    .Partie_2_2 h1{
        font-size: 35px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        color: #45b2d8;
        font-weight: 700;
        margin: 0;
        text-align: center;
    }
    
    .Partie_2_2 p{
        font-size: 18px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        color: #686868;
        font-weight: 500;
        margin-top: 20px;
        padding-bottom: 0;
        line-height: 30px;
        text-align: center;
        padding: 0 15%;
    }
}

