.partie_5{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(1, 48, 94, 1) 0%, rgb(65, 144, 196) 100%);
}

.text-content5{
    position: absolute;
    z-index: 1;
    left: 10%;
    margin-top: 8%;
}

.text-content5 h1{
    color: rgb(250, 250, 250);
    font-size: 24px;
    font-weight: 500;
    line-height: 25px;
    margin: 0;
}

.text-content5 h2{
    color: rgb(250, 250, 250);
    font-size: 20px;
    font-weight: 300;
    margin-top: 5px ;
    margin-bottom: 0;
    font-style: italic;
}

.text-content5 p{
    color: rgb(250, 250, 250);
    font-size: 12px;
    font-weight: 300;
    margin-right: 15% ;
    margin-top: 5px ;
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15%;
    margin-left: -10%;
}

.text-content5 button{
    font-size: 14px;
    font-weight: 700;
    color: rgb(245, 245, 245);
    border: none;
    background: linear-gradient(90deg, rgb(21, 77, 122) 0%, rgba(65, 190, 196, 1) 65%);
    border-radius: 18px;
    padding: 15px 25px;
}

.text-content5 button:hover{
    background: linear-gradient(180deg, rgba(72, 154, 194, 1) 0%, rgba(65, 190, 196, 1) 65%);
}

.image-container5{
    width: 100%;
    margin-top: 50%;
}

.image-container5 img{
    width: 100%;
    padding: 0;
    margin: 0;
    filter: brightness(80%);
}

.waves5 {
    position: relative;
    width: 100%;
    height: 100px;
    margin-top: -55px;
    margin-bottom: 0;
  }
  
  .waves5 img {
    position: absolute;
    width: 100%;
  }
  
  .waves_1_5 {
    height: 100px;
    filter: brightness(220%);
    animation: waveAnimation1 2s ease-in-out infinite alternate;
    z-index: 1;
}

.waves_2_5 {
    height: 100px;
    padding-top: 10px;
    filter: brightness(120%);
    animation: waveAnimation2 2s ease-in-out infinite alternate;
    z-index: 1;
}

.waves_3_5 {
    height: 100px;
    padding-top: 50px;
    filter: brightness(100%);
    animation: waveAnimation3 2s ease-in-out infinite alternate;
    z-index: 1;
}

.waves_4_5 {
    height: 100px;
    padding-top: 20px;
    filter: brightness(135%);
    animation: waveAnimation4 2s ease-in-out infinite alternate;
    z-index: 1;
}

@keyframes waveAnimation1 {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes waveAnimation2 {
    0% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes waveAnimation3 {
    0% {
        transform: translateY(-25px);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes waveAnimation4 {
    0% {
        transform: translateY( 0px);
    }
    100% {
        transform: translateY(-20px);
    }
}

@media screen and (min-width: 768px) {
    .partie_5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .text-content5{
        position: absolute;
        z-index: 1;
        left: 5%;
        margin-top: 5%;
        width: 50%;
        margin-left: 7%;
    }
    
    .text-content5 h1{
        color: rgb(250, 250, 250);
        font-size: 37px;
        font-weight: 700;
        line-height: 50px;
        margin: 0;
        letter-spacing: 1px;
    }

    .partie_5_top{
        width: 90%;
    }
    
    .text-content5 h2{
        color: rgb(250, 250, 250);
        font-size: 30px;
        font-weight: 400;
        margin-top: 10px ;
        margin-bottom: 0;
        font-style: italic;
    }
    
    .text-content5 p{
        color: rgb(250, 250, 250);
        font-size: 23px;
        font-weight: 300;
        margin-right: 15% ;
        margin-top: 2% ;
        line-height: 36px;
        padding-right: 0%;
    }
    
    .button-container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20%;
        margin-left: 50%;

    }
    
    .text-content5 button{
        font-size: 18px;
        font-weight: 700;
        color: rgb(245, 245, 245);
        border: none;
        background: linear-gradient(90deg, rgb(55, 95, 153) 0%, rgba(65, 190, 196, 1) 85%);
        border-radius: 30px;
        padding: 4% 8%;
        cursor: pointer;
    }
    
    .image-container5{
        width: 100%;
        margin-top: 7%;
        display: flex;
        justify-content: center;
        height: auto;
    }
    
    .image-container5 img{
        width: 100%;
        height: auto;
    }
    
    .waves5 {
        position: relative;
        width: 100%;
        height: 100px;
        margin-top: -55px;
        margin-bottom: 0;
      }

      @keyframes waveAnimation1 {
        0% {
            transform: translateY(20px);
        }
        100% {
            transform: translateY(-40px);
        }
    }
    
    @keyframes waveAnimation2 {
        0% {
            transform: translateY(0px);
        }
        100% {
            transform: translateY(-30px);
        }
    }
    
    @keyframes waveAnimation3 {
        0% {
            transform: translateY(-25px);
        }
        100% {
            transform: translateY(-10px);
        }
    }
    
    @keyframes waveAnimation4 {
        0% {
            transform: translateY(0px);
        }
        100% {
            transform: translateY(-35px);
        }
    }
}

