    .cardBody_olissey {
        background-image: url('fondOlissey.png');
        background-size: cover; 
        background-repeat: no-repeat; 
    }

.middleCard{
    padding-top: 10%;
}

.mapField{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #A6A6A6;
    padding-right: 2%;
    cursor: pointer;
}

.mapFieldClicked{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #A6A6A6;
    background-color: #F6F6F6;
    padding-right: 2%;
    cursor: pointer;
}

.mapFieldFinal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #A6A6A6;
    padding-right: 2%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #A6A6A6;
    cursor: pointer;
}

.mapFieldFinalClicked{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F6F6F6;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #A6A6A6;
    padding-right: 2%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #A6A6A6;
    cursor: pointer;
}

.mapField1{
    display: flex;
    align-items: center;
    margin-left: 7%;
}

h4{
    font-size: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-left: 10px;
}

.logoImg_olissey{
    width: 170px;
    margin-top: 30px;
    margin-bottom: 15px;
    padding: 5px;
}

.logo_olissey{
    background-color: #f5f5f500;
}

.bottom_links_olissey{
    display: flex;
    justify-content: space-between;
    padding-left: 20%;
    padding-right: 20%;
    align-items: center;
    background-color: #ffffff00;
}
.bottom_links_olissey a{
    text-decoration: none;
    color: #C2B280;
}

.poweredBy_olissey{
    background-color: #f5f5f500;
    display: flex;
    flex-direction: column;
    padding: 0rem 3.5rem;
}

.poweredBy_olissey button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.2rem;
    border-style: none;
    padding: 12px 10px;
    background-color: rgb(51, 51, 51);
    border-radius: 10px;
    color: #F6F6F6;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 1rem;
}

.poweredBy_olissey img{
    width: 200px;
    margin: 6% 0;
}
