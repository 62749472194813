.cardBody_scit {
    position: relative; /* Ensure the container is positioned */
    background-image: url('walpaper1.jpg');
    background-size: cover; 
    background-repeat: no-repeat; 
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.cardBody_scit::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff00; /* Semi-transparent white overlay */
    pointer-events: none; /* Ensure the overlay doesn't interfere with click events */
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.middleCard{
    padding-top: 10%;
}

.mapField_scit{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #646464;
    padding-right: 2%;
    cursor: pointer;
    background-color: #9e9e9e46;
}

.mapFieldClicked{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #A6A6A6;
    background-color: #F6F6F6;
    padding-right: 2%;
    cursor: pointer;
}

.mapFieldFinal_scit{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #646464;
    padding-right: 2%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #646464;
    cursor: pointer;
    background-color: #9e9e9e46;
}

.mapFieldFinalClicked{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F6F6F6;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #A6A6A6;
    padding-right: 2%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #A6A6A6;
    cursor: pointer;
}

.mapField1{
    display: flex;
    align-items: center;
    margin-left: 7%;
}

.h4_scit{
    font-size: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-left: 10px;
    color: #dfdfdf;
}

.logoImg_scit{
    width: 80%;
}

.logo_scit{
    background-color: #24242410;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5% 0%;
}

.bottom_links_scit{
    display: flex;
    justify-content: center;
    padding-left: 20%;
    padding-right: 20%;
    align-items: center;
    background-color: #ffffff00;
}
.bottom_links_scit a{ 
    text-decoration: none;
    color: #ffffff;
    margin: 0 5%;
}

.poweredBy_scit{
    background-color: #f5f5f500;
    display: flex;
    flex-direction: column;
    padding: 0rem 3.5rem;
}

.poweredBy_scit button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.2rem;
    border-style: none;
    padding: 12px 10px;
    background-color: rgb(51, 51, 51);
    border-radius: 10px;
    color: #F6F6F6;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 1rem;
}

.poweredBy_scit img{
    width: 200px;
    margin: 6% 0;
}
