.Condition_generale{
    background: linear-gradient(111deg, rgba(96, 92, 169, 1) 0%, rgba(72, 154, 194, 1) 35%, rgba(65, 190, 196, 1) 73%, rgba(66, 221, 229, 1) 100%);
    color: white;
}
.Condition_generale1 h1{
    margin-top: 0;
    padding: 10%;
    padding-bottom: 0;
    text-align: center;
}

.Condition_generale1 h2{
    margin-top: 0;
    padding: 0 20%;
    text-align: center;
    font-weight: 500;
}

.Condition_generale2{
    padding: 3% 10%;
    padding-top: 3%;
}

.Condition_generale_h3{
    color: rgb(80, 80, 80);
    text-align: center;
    margin: 0;
    padding: 3% 10%;
    padding-bottom: 30%;
}

@media (max-width: 1095px) {
    .Condition_generale1 h1{
        padding: 35% 6%;
        padding-bottom: 0;
    }
    
    .Condition_generale1 h2{
        padding: 0 6%;
        font-size: 16px;
    }
    
    .Condition_generale2{
        padding: 3% 6%;
    }
    
    .Condition_generale2 h1{
        font-size: 20px;
    }
    
    .Condition_generale_h3{
        padding: 3% 6%;
        font-size: 12px;
        padding-bottom: 60%;
    }
}

