.ContactTop{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 1rem;
}

.ContactTop_2{
  width: 90%;
}

.ContactTop_2-2{
    margin-top: 30px;
    text-align: center;
}

.ContactTop_2-2 p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    color: rgb(243, 243, 243);
    font-weight: 500;
}

.ContactTop_2-3{
    margin-top: 10px;
    text-align: center;
}

.ContactTop_2-3 p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    color: rgb(243, 243, 243);
    font-weight: 500;
}

.ContactTop_2-4{
    margin-top: 10px;
    text-align: center;
}

.ContactTop_2-4 p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    color: rgb(243, 243, 243);
    font-weight: 500;
}

.ContactTop_2-5{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 7rem;
    margin-bottom: 3%;
}

.robot{
    display: none;
}

.service_client{
    display: none;
}

@media screen and (min-width: 1300px) and (max-width: 2000px) {
    .ContactTop{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0rem;
        padding-bottom: 10%;
    }
    
    .service_client{
        width: 300px;
        margin-bottom: 3%;
        display: flex;
    }

    .ContactTop_2{
      width: 100%;
    }
    
    .ContactTop_2-2 p{
        font-size: 35px;
    }

    .ContactTop_2-2 img{
        width: 30px;
    }

    .ContactTop_2-3 img{
        width: 30px;
    }

    .ContactTop_2-4 img{
        width: 30px;
    }
    
    .ContactTop_2-3{
        margin-top: 30px;
    }
    
    .ContactTop_2-3 p{
        font-size: 35px;
    }
    
    .ContactTop_2-4{
        margin-top: 30px;
    }
    
    .ContactTop_2-4 p{
        font-size: 35px;
    }
    
    .ContactTop_2-5{
        margin-top: 60px;
        padding: 0rem 17rem;
        margin-bottom: 0%;
    }
  }