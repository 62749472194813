.produit{
  background:#fefefe;
}

.produit-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Ensure both divs align at the top */
  padding: 7% 15%;
  padding-bottom: 3%;
  position: relative;
}

.produit-image {
  width: 80%;
  object-fit: cover;
  margin-right: 20px; /* Optional spacing between divs */
}

.produit-image img {
  width: 100%;
  margin-top: 2%;
}

.produit-content {
  width: 100%; /* Adjust width as needed */
  padding-left: 5%;
  position: sticky; /* Makes it sticky */
  top: 0; /* Sticks to the top of the viewport */
  align-self: flex-start; /* Ensures it aligns with the start of its parent */
}

  .produit-content h1 {
    font-size: 30px;
    font-weight: 600;
  }

  .produit-content h2 {
    font-weight: 600;
  }

  .produit-content p {
    font-size: 18px;
    line-height: 24px;
  }

  .produit-content button {
    border: none;
    background-color: #4dc8cc;
    color: #f3f3f3;
    font-weight: 600;
    font-size: 18px;
    border-radius: 5px;
    padding: 2% 6%;
    margin: 1% 0%;
    margin-bottom: 3%;
    cursor: pointer;
  }

  .produit-content button:hover {
    background-color: #c9c9c9;
  }

  .order {
    padding: 3% 10%;
    margin-bottom: 2%;
  }

  .order h1{
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
  }

  .order h3{
    font-weight: 500;
    text-align: center;
    color: #42d0d4;
    margin-top: 1%;
  }

  .order-process {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px; /* Adjust margin as needed */
  }
  
  .process-step {
    text-align: center;
    width: 30%; /* Adjust width as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .step-number {
    font-size: 24px;
    width:50px;
    height: 50px;
    font-weight: bold;
    margin-bottom: 10px;
    border: solid 2px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .step-title {
    font-size: 18px;
    font-weight: bold;
    margin: 15px 0%;
  }
  
  .step-description {
    font-size: 16px;
    color: #888;
    font-weight: 500;
  }
  
  .benefits-section {
    text-align: center;
    padding: 0.5% 12%;
    background: radial-gradient(circle at 50% 0%, rgba(69, 149, 163, 1) 0%, rgba(0, 0, 0, 1) 25%);
    color: #fff; /* Text color */
  }
  
  .benefits-section h2 {
    font-size: 2.4em;
    font-weight: bold;

  }
  
  .benefits-section .subtitle {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #6da8b3; /* Slightly lighter subtitle text */
  }
  
  .benefits-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .benefit {
    width: 30%; /* Adjust size as needed */
    max-width: 350px;
    text-align: center;
  }
  
  .benefit img {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
  
  .benefit h3 {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .benefit p {
    font-size: 1em;
    line-height: 1.5em;
    color: #ccc; /* Slightly lighter than main text */
  }

  .benefit_vid{
    width: 50%;
  }
  
  .under_video{
    padding: 5% 12%;
  }

  .under_video h1{
    font-weight: 500;
  }

  .under_video h3{
    font-weight: 500;
    color: #888;
    margin-top: 0;
  }

  .under_video button {
    border: none;
    background-color: #57b5b8;
    color: #f3f3f3;
    font-weight: 600;
    font-size: 16px;
    border-radius: 5px;
    padding: 1.5% 4%;
    cursor: pointer;
  }

  .under_video button:hover {
    background-color:  #808080;
  }

  .produits_similaires{
    padding: 3% 15%;
    padding-bottom: 25%;
    background: linear-gradient(180deg, rgba(254, 254, 254, 1) 10%, rgba(158, 212, 220, 1) 100%);
  }

  .produits_similaires h1{
    font-weight: 700;
    padding: 0 0 0.5% 0;
    border-bottom: solid 2px #d1d1d1;
  }

  .produits_similaires_container {
    display: flex; /* Makes children align horizontally */
    justify-content: space-between; /* Adds space between items */
    gap: 20px; /* Ensures equal gap between items */
  }
  
  .produits_similaires_part {
    flex: 1; /* Makes all divs take equal width */
    max-width: calc(33.333% - 20px); /* Ensures equal width minus the gap */
    text-align: center; /* Centers text below images */
  }

  .produits_similaires_part:hover{
    transform: translateY(-3px); /* Moves the element slightly up on hover */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1); /* Adds a soft shadow effect */
    cursor: pointer;
  }
  
  .produits_similaires_part img {
    width: 100%; /* Image takes the full width of its container */
    height: auto; /* Maintains the aspect ratio */
  }

  .produits_similaires_part h3{
    font-weight: 500;
    font-size: 18px;
    margin-top: 2%;
    color: rgb(97, 97, 97);
  }

  .avis_google{
    margin-top: 3%;
    width: 25%;
  }
  

  @media screen and (max-width: 768px) {
    .produit-image, .produit-content {
      width: 100%; 
      padding-left: 0;
    }
  
    .produit-container {
      flex-direction: column; 
      padding: 7%;
      padding-top: 25%;
    }

    .produit-content h1 {
      font-size: 24px;
      font-weight: 600;
    }
  
    .produit-content h2 {
      font-weight: 600;
      font-size: 20px;
    }

    .produit-container p{
      font-size: 17px;
    }

    .produit-container h3{
      margin-top: 10%;
      font-size: 20px;
      
    }

    .produit-image img:nth-of-type(2) {
      display: none;
  }

  .produit-content button {
    padding: 4% 5%;
  }

  .order h1{
    font-size: 24px;
    margin-top:0;
  }

  .order h3{
    font-weight: 500;
    text-align: center;
    color: #42d0d4;
    margin-top: 3%;
  }

  .order {
    padding: 1% 8%;
    margin-bottom: 10%;
  }

  .order-process {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
  }

  .step-number {
    font-size: 22px;
    width:40px;
    height: 40px;
    margin: 15px 0 0 0 ;
  }
  
  .step-title {
    font-size: 16px;
    margin: 10px 0;
  }

  .process-step {
    width: 100%;
    margin-bottom: 5%;
  }

  .benefits-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .benefit {
    width: 100%; /* Adjust size as needed */
    max-width: 100vh;
    text-align: center;
  }

  .benefit h3{
    font-size: 20px;
  }

  .benefit p{
    font-size: 16px;
  }

  .benefits-section h2 {
    font-size: 1.8em;
    font-weight: bold;

  }
  
  .benefits-section .subtitle {
    font-size: 1em;
    margin-bottom: 40px;
    color: #6da8b3;
  }

  .benefits-section{
    padding: 5% 8%;
  }

  .under_video{
    padding: 20% 0%;
    padding-bottom: 10%;
  }

  .under_video h1{
    font-weight: 500;
    font-size: 20px;
  }

  .under_video h3{
    font-size: 16px;
  }

  .under_video button {
    font-size: 16px;
    padding: 4% 5%;
    margin-top: 3%;
  }

  .produits_similaires{
    padding: 3% 8%;
    padding-bottom: 50%;
  }

  .produits_similaires h1{
    font-size: 24px;
    padding: 0% 0 2% 0;
  }

  .produits_similaires_container {
    flex-wrap:wrap-reverse;
    justify-content: center;
  }
  
  .produits_similaires_part {
    max-width: 50%; /* Ensures equal width minus the gap */
  }

  .produits_similaires_part h3{
    font-size: 14px;
    margin-top: 5%;
  }

  .avis_google{
    width: 50%;
    margin: 5%;
    margin-bottom: -12%;
  }
  
  }