.form_vcf {
    margin-top: 0; /* Adjust this margin as per your preference */
    display: flex;
    justify-content: center;
    width: 100%; /* Set the width to 100% */
  }

  .VCFForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding: 3% 0;
  } 

  .VCFForm input{
    font-family: "montserrat";
    font-weight: 300;
    border: none;
    padding: 4% 5% !important;
    margin: 0;
    color: #7a7a7a !important;
    background-color: #f3f3f3 !important; 
    text-align: left;
    cursor: pointer;
    width: 90%;
    border-style: solid;
    border-width: 1px;
    border-color: #7a7a7a;
    line-height: normal !important;
    margin-top: 4%;
    border-radius: 4px;
  } 

  .VCFForm input:focus::placeholder {
    color: rgb(119, 175, 197);
}

.VCFForm input::placeholder {
    color: rgb(109, 109, 109);
}

  .VCFForm input:focus {
    outline: none;
    border-bottom-color: #A6A6A6;
    font-size: 15px;
    font-weight: 500;
    color: rgb(119, 175, 197);
}

.phone_label{
  width: 100%;
  color: rgb(0, 0, 0);
  font-family: "montserrat";
  font-weight: 700;
  font-size: 22px;
  padding-left: 5%;
  margin-top: 3%;
  margin-bottom: 1%;
}

.VCFForm input[type=file] {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #bbbbbb;
  margin-bottom: 0.5rem;
  font-size: 12px;
  border-top-style: none;
  background-color: #ffffff;
}

.VCFForm input[type="file" i]::-webkit-file-upload-button{
  border-style: none;
  background: #63BBE9;
  padding: 10px 20px;
  border-radius: 20px;
  font-family:"montserrat";
  color: #f8f8f8;
  cursor: pointer;
  font-size: 12px;
}

.react-international-phone-input-container{
  font-family: "montserrat";
  font-weight: 300;
  border: none;
  margin: 0;
  color: #7a7a7a !important;
  background-color: #f3f3f3 !important; 
  text-align: left;
  cursor: pointer;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: #7a7a7a;
  line-height: normal !important;
  border-radius: 5px;
}

.phone_label_container{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.gender-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5%;
}

.gender-button {
  flex: 1;
  padding: 3.5% 0%;
  font-size: 16px;
  font-family: "montserrat";
  border: 1px solid #7a7a7a;
  background-color: transparent;
  color: #7a7a7a;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 5px;
}

.gender-button:not(:last-child) {
    margin-right: 20px;
}

.gender-button.selected {
    background-color: #41BBC3;
    color: white;
}

.gender-button:hover {
    background-color: #408BAE;
    color: white;
}

.gender-button.selected:hover {
    background-color: #41BBC3;
}

.button-group{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  border: 1px solid #7a7a7a;
  padding: 2% 0%;
  border-radius: 5px;
  margin-top: 4%;
}

.button-group1{
  padding: 0% 4%;
  font-family: "montserrat";
  font-size: 14px;
  color: #7a7a7a;
  font-weight: 300;
}

.button-group2{
  flex: 2;
  margin-left: 5%;
}

.prefix-button {
  margin-left: 3%;
  padding: 2% 4%;
  font-size: 14px;
  font-family: "Montserrat";
  border: 1px solid #7a7a7a;
  background-color: transparent;
  color: #7a7a7a;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 5px;
}

.prefix-button.selected {
  background-color: #41BBC3;
  color: white;
}

.prefix-button:hover {
  background-color: #408BAE;
  color: white;
}

.prefix-button.selected:hover {
  background-color: #41BBC3;
}

.VCFForm_part1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.VCFForm_part1 > div {
  width: 47%;
}

.VCFForm_part2 input{
padding: 2% 5% !important;
}

.VCFForm_part2 label{
  padding-left: 2%;
  }

  .Entreprise_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .Entreprise_container > div {
    width: 47%;
  }

  .Entreprise_container div input{
    padding: 4% 5% !important;
  }

  @media screen and (max-width: 950px) {

    .VCFForm_part1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    
    .VCFForm_part1 > div {
      width: 100%;
      
    }
  
    .VCFForm_part2 input {
      padding: 4% 5% !important;
  }
  }
  
  @media screen and (max-width: 768px) {
  .VCFForm input[type=file] {
    font-family:"montserrat";
    color: #bbbbbb;
    margin-bottom: 0.5rem;
  }

  .VCFForm input[type="file" i]::-webkit-file-upload-button{
    border-style: none;
    background: #63BBE9;
    padding: 10px 30px;
    border-radius: 20px;
    font-family:"montserrat";
    color: #f8f8f8;
    cursor: pointer;
  }

  .react-international-phone-country-selector-button{
    height: 100% !important;
  }
  
  .react-international-phone-input{
    width: 100% !important;
    height: 100% !important;

  }
  .phone_label{
    font-size: 14px;
    padding-top: 5%;
    padding-bottom: 2%;
  }

  .VCFForm_part1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

.Entreprise_container div input{
  padding: 4% 5% !important;
}

.Entreprise_container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Entreprise_container > div {
  width: 100%;
}
  }

  .react-international-phone-country-selector-button{
    height: 100% !important;
  }
  .react-international-phone-input{
    width: 100% !important;
    height: 100% !important;
  }
  