.services-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    padding: 5% 10%;
  }
  
  .service {
    flex: 1 1 30%;
    min-width: 250px;
    max-width: 300px;
    padding: 2%;
    background-color: white;
    border-radius: 8px;
    box-shadow: -1px 5px 20px 0px rgb(82 90 101 / 10%);
    text-align: center;
    transition: transform 0.2s ease-in-out;
  }
  
  .service:hover {
    transform: translateY(-10px);
  }
  
  .service-icon {
    width: 64px;
    height: 64px;
    margin-bottom: 10px;
    opacity: 0.85;
  }
  
  .service h2 {
    font-size: 1.3em;
    color: #025add;
    margin: 20px 0;
    font-weight: 500;
  }
  
  .service p {
    font-size: 1.1em;
    color: #3a3a3a;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .services-container {
      flex-direction: column;
    }
  
    .service {
      flex: 1 1 100%;
      max-width: 100%;
      padding: 5% 8%;
    }

    .service p {
        font-size: 1em;
        color: #3a3a3a;
      }
  }
  