.cloudinary-button {
    font-family: "montserrat";
    font-weight: 300;
    border: none;
    padding: 2% 3% !important;
    margin: 0;
    color: #7a7a7a !important;
    background-color: #f3f3f3 !important; 
    text-align: left;
    cursor: pointer;
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: #7a7a7a;
    line-height: normal !important;
    margin: 2% 0%;
  }

  @media screen and (max-width: 768px) {
    .cloudinary-button {
      padding: 2.5% 5% !important;
      margin-top: 4%;
    }
  }