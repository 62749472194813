.politique-confidentialite{
    background: linear-gradient(111deg, rgba(96, 92, 169, 1) 0%, rgba(72, 154, 194, 1) 35%, rgba(65, 190, 196, 1) 73%, rgba(66, 221, 229, 1) 100%);
    padding: 5% 11%;
    color: white;
    padding-bottom: 30%;
}
.politique-confidentialite h1{
    margin-top: 0;
    padding: 5%;
    text-align: center;
}

@media (max-width: 1095px) {
    .politique-confidentialite{
        padding: 40% 6%;
        padding-bottom: 50%;
    }
}