.Partie_3 {
    background: linear-gradient(314deg, rgba(92, 185, 229, 1) 0%, rgba(17, 166, 188, 1) 100%);
    display: flex;
    justify-content: space-between;
  }
  
  .Partie_3_1 {
    position: relative;
    z-index: 1;
    text-align: left;
    margin-top: 35%;
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.5s, transform 1s;
    }
    
    .animatedPartie_3_1 {
    opacity: 1;
    transform: translateX(0);
    }

  .Partie_3_1 h1{
    color: rgb(255, 255, 255);
    font-size: 23px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
    padding-left: 5%;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 0px;
    padding-right: 50%;
  }

  .Partie_3_1 h2{
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
    padding-left: 5%;
    font-weight: 400;
    font-style: italic;
    margin-top: 10px;
    padding-right: 40%;
    margin-bottom: 0;
  }

  .Partie_3_1 p{
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
    padding-left: 5%;
    font-weight: 300;
    margin-top: 10px;
    padding-right: 10%;
    margin-bottom: 250px;
  }
  
  
  .Partie_3_2 {
    position: absolute;
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 5%;
  }

  .rightImg{
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s, transform 1s;
    }
    
    .animatedrightImg {
    opacity: 1;
    transform: translateX(0);
    }
  
  .Partie_3_2 img {
    width: 60%;
  }
  
  .partie3_2_img {
    width: 100%;
    margin-top: -270px;
  }

  .partie3_3_img {
    width: 100%;
    margin-top: -70px;
    filter: brightness(100%);
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    
    .Partie_3_1 {
      margin-top: 7%;
      margin-bottom: 52%;
      padding-left: 7%;
      opacity: 0;
      transform: translateX(-100%);
      transition: opacity 0.5s, transform 1s;
      }
      
      .animatedPartie_3_1 {
      opacity: 1;
      transform: translateX(0);
      }
  
    .Partie_3_1 h1{
      font-size: 37px;
      font-weight: 700;
      padding-right: 0%;
    }
  
    .Partie_3_1 h2{
      color: rgb(255, 255, 255);
      font-size: 30px;
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
      padding-left: 5%;
      font-weight: 400;
      font-style: italic;
      margin-top: 10px;
      padding-right: 40%;
      margin-bottom: 0;
    }
  
    .Partie_3_1 p{
      color: rgb(255, 255, 255);
      font-size: 23px;
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
      padding-left: 5%;
      font-weight: 300;
      margin-top: 2%;
      padding-right: 50%;
      margin-bottom: 5%;
      line-height: 36px;
    }
    
    
    .Partie_3_2 {
      position: absolute;
      z-index: 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 5%;
    }
    
    .Partie_3_2 img {
      width: 30%;
    }
    
    .partie3_2_img {
      width: 100%;
      margin-top: -50%;
    }
  
    .partie3_3_img {
      width: 100%;
      margin-top: -20%;
      filter: brightness(100%);
      margin-bottom: 0;
    }
  }