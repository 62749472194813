

/* Animation */
@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-220px * 7)); }
}

/* Styling */
.slider {
  background: transparent;
  height: 85px; /* Fixed height for the slider */
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 60vw; 
  border-radius: 0;
}

.slider::before,
.slider::after {
  background: linear-gradient(to right, rgb(248, 248, 248) 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 120px;
  position: absolute;
  width: 250px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(auto * 13);
}

.slide {
  height: 75px; /* Full height of the slider */
  width: auto;
  margin: 0% 6%;
  filter:grayscale(100%) brightness(100%) contrast(50%);
}

/* Image styling */
.slide img {
  height: 100%;
  width: auto; /* Preserve aspect ratio */
  object-fit: cover; /* Cover the full height without distortion */
}

@media (max-width: 750px) {
  .slider {
    height: 80px; /* Fixed height for the slider */
    width: 100vw; 
  }
  .slider::before,
.slider::after {
  height: 80px;
  width: 180px;
  background: linear-gradient(to right, rgba(248, 248, 248, 0.875) 0%, rgba(255, 255, 255, 0.021) 100%);
  width: 130px;
}
.slide {
  height: 55px; /* Full height of the slider */
  width: auto;
  margin: 0% 5%;
}
.slide-track {
  animation: scroll 60s linear infinite;
  display: flex;
  width: calc(auto * 13);
}

/* Animation */
@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-170px * 7)); }
}
}