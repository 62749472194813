.HomeFinal{
    background: linear-gradient(134deg, rgba(66, 189, 196, 1) 0%, rgba(71, 159, 195, 1) 100%);
}

.HomeFinal1{
    padding: 5% 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HomeFinal1_text{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding-left: 5%;
}

.HomeFinal1_text h3{
    color: #f3f3f3;
    font-weight: 300;
    font-size: 2.2vw; /* Responsive font size */
    margin: 0;
}

.HomeFinal1_text h1{
    color: #f3f3f3;
    font-weight: 700;
    font-size: 6.7vw; /* Responsive font size */
    margin: 0;
}

.HomeFinal1_text h2{
    color: #f3f3f3;
    font-weight: 300;
    font-size: 1.7vw; /* Responsive font size */
    margin: 0;
    padding-right: 25%;
}

.HomeFinal1_btn{
    display: flex;
}

.firstbtn {
background-color: #f3f3f3;
border: none;
margin: 2%;
border-radius: 30px;
padding: 1.5% 3%;
color: #42BDC4;
font-family: "montserrat";
font-size: 18px;
font-weight: 500;
display: flex;
align-items: center;
justify-content: space-around;
cursor: pointer;
}

.firstbtn:hover{
    background-color: #f3f3f3e2;
    }

.firstbtn img{
    width: 22px;
    margin-right: 5%;
    }

    .secondbtn {
        background-color:transparent;
        border: solid #f3f3f3 2px;
        margin: 2%;
        border-radius: 30px;
        padding: 1.5% 3%;
        color: #f3f3f3;
        font-family: "montserrat";
        font-size: 22px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-around;
        white-space: nowrap;
        cursor: pointer;
        }

        .secondbtn:hover{
            background-color: #f3f3f325;
            }
        
        .secondbtn img{
            width: 24px;
            margin-right: 5%;
            }

.HomeFinal1_img {
    width: 100%;
    max-width: 350px;
    margin: 0 ; /* Center the image container */
}

.HomeFinal1_img img {
    width: 100%; /* Ensure the image takes the full width of the container */
    height: auto; /* Maintain the aspect ratio */
    display: block; /* Remove the default inline spacing below images */
}

.HomeFinal2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0% 12%;
}

.HomeFinal2_1{
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
}

.HomeFinal2_1 img{
    height: 64px;
}

.HomeFinal2_1 div{
    display: flex;
    flex-direction: column;
}

.HomeFinal2_1 div h1{
    color: #f3f3f3;
    font-size: 18px;
    margin: 0;
    padding-left: 5%;
}

.HomeFinal2_1 div p{
    color: #f3f3f3;
    font-size: 15px;
    font-weight: 300;
    padding-left: 5%;
    padding-right: 20%;
}

.Confiance{
    background-color: #f8f8f8;
    padding-top: 5%;
}

.Confiance_content{
    text-align: center;
    margin-bottom: 5%;
}

.Confiance_content h1{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0;
}

.Confiance_content h2{
    font-size: 20px;
    font-weight: 500;
    color: #515151;
}

.Confiance_content img{
    width: 70px;
}

.HomeFinal3{
    background: linear-gradient(130deg, rgba(72, 154, 194, 1) 0%, rgba(65, 190, 196, 1) 65%);
    padding-top: 5%;
}

.HomeFinal3_1{
    text-align: center;
    color: #f3f3f3;
}

.HomeFinal3_1 h1{
    margin: 0%;
    font-size: 30px;
    margin-bottom: 0;
}

.HomeFinal3_1 h2{
    font-size: 22px;
    font-weight: 500;
    margin-top: 0.5%;
}

.HomeFinal3_2{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5% 15%;
    margin-bottom: 1%;
    background-color:#f3f3f3 ;
    border-radius: 20px;
    padding: 4%;
}

.HomeFinal3_2_content{
    width: 50%;
    padding-right: 10%;
}

.HomeFinal3_2_content h1{
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 5%;
    padding-right: 15%;
}

.HomeFinal3_2_content p{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5%;
    padding-right: 10%;
}

.HomeFinal3_2_content button{
    margin-top: 1%;
    background: linear-gradient(90deg, rgba(72, 154, 194, 1) 0%, rgba(65, 190, 196, 1) 65%);
    border: none;
    border-radius: 30px;
    padding: 4% 5%;
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.HomeFinal3_2_content button:hover{
    background: linear-gradient(180deg, rgba(72, 154, 194, 1) 0%, rgba(65, 190, 196, 1) 65%);
}

.HomeFinal3_2_img {
    width: 100%;
    max-width: 350px;
    margin: 0 ; /* Center the image container */
}

.HomeFinal3_2_img img {
    width: 100%; /* Ensure the image takes the full width of the container */
    height: auto; /* Maintain the aspect ratio */
    display: block; /* Remove the default inline spacing below images */
}

.HomeFinal3_2_bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5% 15%;
    margin-bottom: 0%;
    padding-bottom: 5%;
    margin-top: 1.5%;
    gap: 2%;
}

.HomeFinal3_2_bottom h1{
    color: #f3f3f3;
    font-size: 20px;
    
}

.HomeFinal3_2_bottom p{
    color: #f3f3f3;
    font-size: 16px;
    padding-right: 20%;
}

.HomeFinal3_2_bottom_content div {
    width: 100%;
    height: 42vh; /* Adjust the percentage as needed */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: #f3f3f3;
    border-radius: 15px;
    margin: 1;
    overflow: hidden;
}


  .HomeFinal3_2_bottom_content img {
    width: 100%; /* Take up 90% of the div's width */
    height: auto; /* Maintain aspect ratio */
    max-height: 100%; /* Ensure image doesn't exceed the div's height */
  }

  .partnership-container {
    width: 100%;
    padding: 5% 0;
    text-align: center;
    background: radial-gradient(ellipse at 0% 0%, rgba(248, 248, 248, 1) 70%, rgba(70, 164, 195, 1) 80%);
  }
  
  .partnership-container h2 {
    font-size: 2.5em; /* Adjust size as needed */
    font-weight: 700;
    background: linear-gradient(90deg, rgb(113, 56, 205) 0%, rgba(82, 197, 200, 1) 70%);
    -webkit-background-clip: text; /* Clips background to text */
    -webkit-text-fill-color: transparent; /* Makes text transparent to show the gradient */
    margin-bottom: 20px; /* Optional for spacing */
  }
  
  .announcement-text {
    color: #515151;
    font-size: 20px;
    font-weight: 500;
    padding: 0 20%;
    line-height: 160%;
  }
  
  .partner-details {
    display: flex;
    align-items:center;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
  }
  
  .partner-logo {
    max-width: 250px;
    height: auto;

  }
  
  .partner-info {
    text-align: left;
  }
  
  .partner-info h3 {
    color: #6475CB;
  }
  
  .partner-info p {
    margin: 5px 0;
    color: #515151;
  }

  /* General Styling */
.popular-products {
    text-align: center;
    background-color: #F3F3F3;
    padding-top: 3%;
  }
  
  .popular-products h2 {
    font-size: 2.2em;
    margin-bottom: 3%;
    font-weight: 600;
    background: linear-gradient(90deg, rgb(113, 56, 205) 0%, rgba(82, 197, 200, 1) 70%);
    -webkit-background-clip: text; /* Clips background to text */
    -webkit-text-fill-color: transparent; /* Makes text transparent to show the gradient */
  }
  
  /* Product Container */
  .products-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  
  /* Product Card */
  .product-card {
    flex: 1 1 calc(25% - 20px); /* 4 products per row on large screens */
    max-width: 255px; /* Optional for uniform size */
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    cursor: pointer;
  }
  
  .product-card:hover {
    transform: scale(1.05);
  }
  
  .product-card img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .product-name {
    font-weight: bold;
    font-size: 1em;
    margin: 10px 0;
    color: #555;
  }
  
  .product-price {
    color: #5F8BCA;
    font-weight: bold;
    font-size: 1.1em;
  }

/* Media query for larger screens to set a maximum font size */
@media (min-width: 1550px) {
    .HomeFinal1_text h3{
        font-size: 26px; /* Fixed size for larger screens */
    }

    .HomeFinal1_text h1{
        font-size: 80px; /* Fixed size for larger screens */
    }

    .HomeFinal1_text h2{
        font-size: 24px; /* Fixed size for larger screens */
    }

    .product-card {
        flex: 1 1 calc(25% - 20px); /* 4 products per row on large screens */
        max-width: 290px; /* Optional for uniform size */
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 10px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s;
        cursor: pointer;
      }
}

@media (max-width: 1050px) {
    .firstbtn {
        font-size: 18px;
        }
        
        .firstbtn img{
            width: 20px;
            }
        
            .secondbtn {
                font-size: 18px;
                }

                .secondbtn img{
                    width: 20px;
                    }

                    .HomeFinal2_1 div h1{
                        font-size: 18px;
                    }
                    
                    .HomeFinal2_1 div p{
                    font-size: 14px;
                    padding-right: 0%;
                    }

                    .HomeFinal2 {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-start;
                        margin-bottom: 0%;
                        padding: 0% 5%;
                    }
}

@media (max-width: 750px) {
    .HomeFinal1{
        padding: 30% 4%;
        padding-bottom: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .HomeFinal1_btn{
        display: flex;
        flex-direction: column;
        width: 55%;
        margin-top: 5%;
    }

    .firstbtn {
        font-size: 16px;
        padding: 7% 10%;
        }
        
        .firstbtn img{
            width: 16px;
            margin: 0;
            }
        
            .secondbtn {
                font-size: 16px;
                padding: 7%;
                }
                
                .secondbtn img{
                    width: 16px;
                    margin: 0;
                    }

    .HomeFinal1_text{
        padding-left: 5%;
    }

    .HomeFinal1_text h3 {
        font-size: 14px; /* Responsive font size */
        margin-bottom: 3%;
        font-weight: 500;
    }

    .HomeFinal1_text h1 {
        font-size: 8vw;
    }
    
    .HomeFinal1_text h2 {
        font-size: 16px; /* Responsive font size */
        padding-right: 0;
        margin-top: 3%;
        font-weight: 500;
    }

    .HomeFinal1_img {
        width: 70%;
        max-width: 350px;
        margin: 10% 0;
    }

    .HomeFinal2_1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2%;
    }

    .HomeFinal2_1 img{
        width: 56px;
        height: 56PX;
    }

    .HomeFinal2_1 div h1{
        font-size: 20px;
        margin-top: 2%;
        text-align: center;
    }
    
    .HomeFinal2_1 div p{
    font-size: 14px;
    font-weight: 300;
    padding-right: 0%;
    padding: 0% 5%;
    text-align: center;
    margin: 2% 0;
    }

    .HomeFinal2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        margin-bottom: 0%;
        padding: 0% 5%;
    }

    .Confiance{
        background-color: #f8f8f8;
        padding-top: 5% ;
    }
    
    .Confiance_content{
        text-align: center;
        margin-bottom: 5%;
        padding-top: 3%;
    }

    .Confiance_content h1{
        font-size: 20px;
        padding: 0% 10%;
    }
    
    .Confiance_content h2{
        font-size: 13px;
        font-weight: 500;
        padding: 0% 10%;
        color: #515151;
    }
    
    .Confiance_content img{
        width: 50px;
    }

    .HomeFinal3_1 h1{
        margin: 0%;
        font-size: 22px;
        padding: 0% 5%;
    }
    
    .HomeFinal3_1 h2{
        font-size: 18px;
        font-weight: 500;
        padding: 0% 10%;
    }

    .HomeFinal3_2{
        flex-direction: column-reverse;
        margin: 7%;
        margin-bottom: 3%;
        padding: 5%;
    }
    
    .HomeFinal3_2_content{
        width: 100%;
        padding-right: 0;
        text-align: center;
    }

    .HomeFinal3_2_content h1{
        font-size: 20px;
        padding-right: 0;
    }
    
    .HomeFinal3_2_content p{
        font-size: 16px;
        padding-right: 0;
    }
    
    .HomeFinal3_2_content button{
        padding: 6%;
        margin-bottom: 2%;
    }

    .HomeFinal3_2_img img {
        width: 60%; /* Ensure the image takes the full width of the container */
        margin: auto;
    }

    .HomeFinal3_2_bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 5% 7%;
        margin-top: 4%;
        margin-bottom: 0%;
        padding-bottom: 10%;
        gap: 0;
    }

    .HomeFinal3_2_bottom_content{
        margin-bottom: 2%;
    }
    
    .HomeFinal3_2_bottom h1{
        color: #f3f3f3;
        font-size: 20px;
    }
    
    .HomeFinal3_2_bottom p{
        color: #f3f3f3;
        font-size: 14px;
        padding-right: 20%;
    }
    
    .HomeFinal3_2_bottom_content div {
        width: 100%;
        height: 100%; /* Adjust the percentage as needed */
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-color: #f3f3f3;
        border-radius: 15px;
        margin: 1;
        overflow: hidden;
    }
    
    
      .HomeFinal3_2_bottom_content img {
        width: auto; /* Take up 90% of the div's width */
        height: auto; /* Maintain aspect ratio */
        max-height: 100%; /* Ensure image doesn't exceed the div's height */
        max-width: 100%;
      }
      
      .partnership-container h2 {
        font-size: 1.3em;
        padding: 0 7%;
      }
      
      .announcement-text {
        font-size: 15px;
        padding: 0 7%;
        line-height: 150%;
      }
      
      .partner-details {
        flex-direction: column;
        gap: 10px;
        margin-top: 30px;
      }
      
      .partner-logo {
        max-width: 200px;
    
      }
      
      .partner-info {
        text-align: left;
        padding: 0 15%;
      }
      
      .partner-info h3 {
        color: #6475CB;
        font-size: 14px;
        margin-bottom: 0;
      }
      
      .partner-info p {
        margin: 10px 0;
        font-size: 13px;
      }

      .popular-products {
        padding-top: 10%;
      }
      
      .popular-products h2 {
        font-size: 1.6em;
        margin-bottom: 7%;
        font-weight: 700;
      }
      
      .products-container {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }
      
      .product-name {
        font-weight: bold;
        font-size: 1em;
        margin: 5% 0;
      }
      
      .product-price {
        font-size: 1.1em;
        margin: 2% 0;
      }
    
      .product-card {
        max-width: 75%;
      }
}