:root {
    --fir-font-article: "adobe-garamond-pro", "Times New Roman", Times;
    --fir-font-header: "foco", Helvetica;
    --fir-blue-twitter-alpha: #1ea7c250;
    --fir-color-grey: rgba(0, 0, 0, 0.40);
  }
  
  
  .fir-clickcircle {
    height: 80px;
    width: 80px;
    border-radius: 100px;
    cursor: pointer;
  }
  
  .fir-image-figure {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px;
    position: relative;
    text-decoration: none;
  }
  
  .fir-image-figure .caption,
  .fir-image-figure figcaption {
    padding-left: 15px;
  }
  
  html.wf-active .fir-image-figure .fig-author-figure-title {
    font-family: var(--fir-font-header);
    font-size: 16px;
  }
  
  .fir-image-figure .fig-author-figure-title {
    color: var(--fir-color-grey);
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 400;
    font-size: 15px;
    margin-top: 2px;
  }
  
  .fir-imageover {
    position: relative;
    display: flex;
  }
  
  .fir-imageover-color {
    height: 80px;
    width: 80px;
    position: absolute;
    background: var(--fir-blue-twitter-alpha);
    background-image: none;
    border-radius: 100px;
    cursor: pointer;
    animation: fadeInFadeOut 10s infinite;
    top: 0;
    left: 0;
  }
  
  .fir-imageover-image {
    position: absolute;
    top: 0;
    left: 0;
    animation: fadeInFadeOut 10s infinite;
  }
  
  @keyframes fadeInFadeOut {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.swiper-button-next,
.swiper-button-prev {
  color: #1EA8C2 !important;
  font-size: 24px; 
  cursor: pointer;
  display: flex;
  align-items: center;
}
  