.demarche-rse {
    padding-top: 8%;
    background: linear-gradient(111deg, rgba(96, 92, 169, 1) 0%, rgba(72, 154, 194, 1) 35%, rgba(65, 190, 196, 1) 73%, rgba(66, 221, 229, 1) 100%);
  }
  
  .section {
    margin-bottom: 2%;
    padding: 0% 15%;
  }

  .section_last {
    margin-bottom: 30%;
    padding: 0% 15%;
  }
  
  .demarche-rse-h1 {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 5%;
    color: white;
  }

  .section h2 {
    color: rgb(183, 234, 235);
  }
  
  .section_last h2{
    color: rgb(183, 234, 235);
  }
  
  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .point {
    position: relative;
    flex: 1 1 45%;
    padding: 20px;
    color: white;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
    min-height: 150px;
    overflow: hidden; /* Ensures the overlay is contained within the border-radius */
    z-index: 0;
    text-align: center;
  }
  
  .point::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dark overlay with 50% opacity */
    z-index: -1;
  }
  
  .point p {
    position: relative;
    z-index: 1;
    padding: 10%;
    font-size: 20px;
  }
  
  .bg1 { background-image: url('./rse1.webp'); }
  .bg2 { background-image: url('./rse2.webp'); }
  .bg3 { background-image: url('./rse3.webp'); }
  .bg4 { background-image: url('./rse4.webp'); }
  .bg5 { background-image: url('./rse5.webp'); }
  .bg6 { background-image: url('./rse6.webp'); }
  .bg7 { background-image: url('./rse7.webp'); }
  .bg8 { background-image: url('./rse8.webp'); }
  .bg9 { background-image: url('./rse9.webp'); }
  .bg10 { background-image: url('./rse10.webp'); }
  .bg11 { background-image: url('./rse11.webp'); }
  .bg12 { background-image: url('./rse12.webp'); }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .content {
      flex-direction: column;
    }

    .demarche-rse {
        padding-top: 30%;
        background: linear-gradient(111deg, rgba(96, 92, 169, 1) 0%, rgba(72, 154, 194, 1) 35%, rgba(65, 190, 196, 1) 73%, rgba(66, 221, 229, 1) 100%);
      }

      .section {
        margin-bottom: 2%;
        padding: 0% 2%;
      }
    
      .section_last {
        margin-bottom: 60%;
        padding: 0% 2%;
      }
      
      .demarche-rse-h1 {
        font-size: 1.8em;
        text-align: center;
        margin-bottom: 15%;
        color: white;
      }
      
      .demarche-rse-h2 {
        margin-bottom: 5%;
        line-height: 1.5em;
      }
      
      .point {
        border-radius: 12px;
      }
      
      .point p {
        padding: 3%;
        font-size: 16px;

      }
  }
  