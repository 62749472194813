.carteStyle_scit{
    text-align: center;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    background-color: #ffffff;
}

.custom-color .MuiCircularProgress-svg .MuiCircularProgress-circle {
    stroke: #16A6BE;
  }