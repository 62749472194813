:root {
    --default-font-family: "montserrat", "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }

  .FooterDesktop{
    margin-top: -30%;
  }
  
  .main-container {
    overflow: hidden;
  }
  
  .main-container,
  .main-container * {
    box-sizing: border-box;
  }
  
  input,
  select,
  textarea{
    outline: 0;
  }
  
  .main-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 99px;
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding: 25% 12% 40px 12%; /* Top padding 5%, bottom 40px, left and right 40px */
        background-image: url('vagues.png'); /* Path to your image */
        background-size: cover; /* Adjusts the image size to cover the entire div */
        background-repeat: no-repeat;
        box-sizing: border-box; /* Ensures padding is included in the element's total width */
  }
  .frame {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 56px;
    position: relative;
    width: 173.558px;
  }
  .logo {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    min-width: 0;
    height: 96.095px;
    z-index: 1;
    overflow: hidden;
    margin: auto;
    margin-bottom: 10%;
  }
  .frame-17 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 13px;
    position: relative;
    min-width: 0;
    z-index: 26;
  }
  .inscription {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 10px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 27;
  }
  .frame-18 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    z-index: 28;
  }
  .frame-19 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    width: 117px;
    z-index: 29;
  }
  .mail {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 30;
    overflow: hidden;
  }
  .icon {
    position: relative;
    width: 20px;
    height: 16px;
    margin: 4px 0 0 2px;
    z-index: 31;
  }
  .adresse-mail {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 32;
  }
  .line {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    height: 1px;
    background: #c7c7c7;
    background-size: cover;
    z-index: 33;
  }
  .frame-1a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 104px;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    background: #7a7a7a;
    border: none;
    z-index: 34;
    border-radius: 5px;
  }

  .frame-1a:hover{
    background: linear-gradient(90deg, rgba(72, 154, 194, 1) 0%, rgba(65, 190, 196, 1) 65%);
  }
  .confirmer {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #f3f3f3;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 600;
    line-height: 9px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 35;
  }
  .frame-1b {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 48px;
    position: relative;
    width: 935px;
    z-index: 36;
  }
  .frame-1c {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 36px;
    position: relative;
    width: 196px;
    z-index: 37;
  }
  .informations {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    min-width: 0;
    height: 10px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 38;
  }
  .frame-1d {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    min-width: 0;
    z-index: 39;
  }

  .frame-1d span{
cursor: pointer;
  }

  .frame-1d span:hover{
    color:#66CBCD ;
    }

  .frame-1f span{
        cursor: pointer;
    }
        
  .frame-1f span:hover{
    color:#66CBCD ;
    }

    .frame-21 span{
      cursor: pointer;
        }
      
        .frame-21 span:hover{
          color:#66CBCD ;
            }
    
  .techtide {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 40;
  }
  .devis {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 41;
  }
  .faq {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 42;
  }
  .teimoignages {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 43;
  }
  .partenaires {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 44;
  }
  .frame-1e {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 36px;
    position: relative;
    width: 196px;
    z-index: 45;
  }
  .nos-services {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    min-width: 0;
    height: 10px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 46;
  }
  .frame-1f {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    min-width: 0;
    z-index: 47;
  }
  .enterprises {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 48;
  }
  .personalization {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 49;
  }
  .collaboration {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 50;
  }
  .rse-approach {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 51;
  }
  .how-it-works {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 52;
  }
  .frame-20 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 36px;
    position: relative;
    width: 196px;
    z-index: 53;
  }
  .useful-links {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    min-width: 0;
    height: 10px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 54;
  }
  .frame-21 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    min-width: 0;
    z-index: 55;
  }
  .products {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 56;
  }
  .support {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 57;
  }
  .terms-and-conditions {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 58;
  }
  .privacy-policy {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 59;
  }
  .legal-mentions {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 60;
  }
  .frame-22 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 36px;
    position: relative;
    width: 203px;
    z-index: 61;
  }
  .contact-us {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    min-width: 0;
    height: 10px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 62;
  }
  .frame-23 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 19px;
    position: relative;
    min-width: 0;
    z-index: 63;
  }
  .frame-24 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 13px;
    position: relative;
    width: 157px;
    z-index: 64;
  }
  .address {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    min-width: 0;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 700;
    line-height: 9px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 65;
  }
  .address-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    width: 168px;
    height: 22px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    text-align: left;
    z-index: 66;
  }
  .frame-25 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 74px;
    z-index: 67;
  }
  .phone-number {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    min-width: 0;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 700;
    line-height: 9px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 68;
  }
  .phone-number-26 {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    min-width: 0;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 69;
  }
  .phone-number-27 {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    min-width: 0;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 70;
  }
  .frame-28 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 185px;
    z-index: 71;
  }
  .email {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 700;
    line-height: 9px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 72;
  }
  .email-29 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 185px;
    height: 9px;
    color: #7a7a7a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 9px;
    text-align: left;
    white-space: nowrap;
    z-index: 73;
  }
  .Footerbutton {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    background: #7a7a7a;
    border: none;
    z-index: 74;
    border-radius: 5px;
    cursor: pointer;
  }
  .Footerbutton:hover {
    background: linear-gradient(90deg, rgba(72, 154, 194, 1) 0%, rgba(65, 190, 196, 1) 65%);
  }
  .contact-form {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 9px;
    color: #f3f3f3;
    font-family: Montserrat, var(--default-font-family);
    font-size: 13px;
    font-weight: 600;
    line-height: 9px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 75;
  }
  .frame-2a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 41px;
    margin-top: -4%;
    background-color: #f3f3f3;
  }
  .empty {
    flex-shrink: 0;
    position: relative;
    width: 52px;
    height: 52px;
    background-size: cover;
    z-index: 77;
  }

/* First icon (Instagram Purple) */
.empty:nth-child(1) img:hover {
  filter: brightness(0) saturate(100%) invert(65%) sepia(50%) saturate(2000%) hue-rotate(290deg) brightness(80%) contrast(80%);
}

/* Second icon (Blue) */
.empty:nth-child(2) img:hover {
  filter: brightness(0) saturate(100%) invert(50%) sepia(10%) saturate(2000%) hue-rotate(180deg) brightness(100%) contrast(100%);
}

/* Third icon (Green) */
.empty:nth-child(3) img:hover {
  filter: brightness(0) saturate(100%) invert(40%) sepia(60%) saturate(1200%) hue-rotate(90deg) brightness(100%) contrast(95%);
}

.all_rights{
  margin-top: 1%;
  background-color: rgb(36, 36, 36);
  color: #b2b2b2;
  padding: 0.8%;
  text-align: center;
  font-size: 14px;
}

  @media (max-width: 1600px) {
    .main-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      gap: 30px;
      position: relative;
      width: 100%;
      margin: 0 auto;
      padding: 30% 30px 29px 30px;
    }
  }

  @media (max-width: 1095px) {
    .FooterDesktop{
      margin-top: -25%;
    }
    
    .main-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      gap: 30px;
      position: relative;
      width: 100%;
      margin: 0 auto;
      padding: 30% 30px 29px 30px;
      background-image: url('SmallVagues.png'); /* Path to your image */
      background-size: cover; /* Adjusts the image size to cover the entire div */
      background-repeat: no-repeat;
      box-sizing: border-box; /* Ensures padding is included in the element's total width */
    }

    .frame-1b {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap; /* Allow wrapping to the next line */
      gap: 26px;
      position: relative;
      width: 100%; /* Adjust width to allow wrapping */
      z-index: 36;
    }
    
    .frame-2a {
      gap: 20px;
      padding-top: 3%;
    }

    .all_rights{
      padding: 3%;
      font-size: 12px;
    }
  }

  @media (max-width: 500px) {
    .FooterDesktop{
      margin-top: -60%;
    }
    
    .main-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: nowrap;
      gap: 30px;
      position: relative;
      width: 100%;
      margin: 0 auto;
      padding: 50% 30px 29px 30px;
      background-image: url('MobileVagues.png'); /* Path to your image */
      background-size: cover; /* Adjusts the image size to cover the entire div */
      background-repeat: no-repeat;
      box-sizing: border-box; /* Ensures padding is included in the element's total width */
      background-position: center;
    }

    .frame-1b {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: nowrap;
      flex-shrink: 0;
      gap: 26px;
      position: relative;
      width: 203px;
      z-index: 36;
    }

    .frame-2a {
      gap: 20px;
    }
  }
  
  